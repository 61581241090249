import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from "../handlers/ApiHandler";

class InvoiceStore {
  invoice = null;
  totalIncome = 0;
  paginatedInvoices = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedInvoices(
    page = 1,
    pageSize = 10,
    sortBy = '-invoiceDate',
    filter = {},
    search = '',
    searchField = 'orderNumber'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/invoices?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      runInAction(() => {
        this.paginatedInvoices = { ...pagination, results: results };
      })
      return { results, ...pagination };
    } catch (error) {
      if (error.response.data.message === 'No documents found.') {
        const empty = { results: [], totalCount: 0, currentPage: 0, pageSize: 0, totalPages: 0 };
        this.paginatedInvoices = empty;
        return empty;
      } else {
        console.error("Error fetching paginated invoices:", error);
        throw error;
      }
    }
  }

  async fetchInvoiceById(invoiceId) {
    try {
      const invoice = await apiHandler.get(`/invoices/${invoiceId}`);
      return invoice;
    } catch (error) {
      console.error("Error fetching invoice by ID:", error);
    }
  }

  async updateInvoice(invoiceId, invoiceData) {
    try {
      const updatedInvoice = await apiHandler.put(`/invoices/${invoiceId}`, invoiceData);
      const index = this.paginatedInvoices.results.findIndex(invoice => invoice._id === invoiceId);
      if (index > -1) {
        this.paginatedInvoices.results[index] = updatedInvoice;
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
      throw error;
    }
  }

  /**
   * Create a new invoice
   * NOTE - does not add 'notes' field to new invoice
   *  That is done in separate addNote function
   * @param {*} invoiceData
   */
  async createInvoice(invoiceData) {
    try {
      const newInvoice = await apiHandler.post('/invoices', invoiceData);
      this.paginatedInvoices.results.push(newInvoice);
      return newInvoice;
    } catch (error) {
      console.error("Error creating invoice:", error);
      throw error;
    }
  }

  /**
   * Delete an invoice
   * This will permanently delete an invoice from the database
   * @param {*} invoiceId 
   */
  async deleteInvoice(invoiceId) {
    try {
      await apiHandler.delete(`/invoices/${invoiceId}`);
      this.paginatedInvoices.results = this.paginatedInvoices.results.filter(invoice => invoice._id !== invoiceId);
    } catch (error) {
      console.error("Error deleting invoice:", error);
    }
  }

  /**
   * Add a note to an invoice
   * @param {*} invoiceId 
   * @param {*} noteData 
   */
  async addNoteToInvoice(invoiceId, noteData) {
    const content = { content: noteData }
    try {
      const updatedInvoice = await apiHandler.post(`/invoices/${invoiceId}/notes`, content);
      const index = this.paginatedInvoices.results.findIndex(invoice => invoice._id === invoiceId);
      if (index > -1) {
        this.paginatedInvoices.results[index] = updatedInvoice;
      }
    } catch (error) {
      console.error("Error adding note to invoice:", error);
    }
  }

  /**
   * Delete a note from an invoice
   * @param {*} invoiceId 
   * @param {*} noteId 
   */
  // Not yet implemented in the API
  async deleteNoteFromInvoice(invoiceId, noteId) {
    try {
      await apiHandler.delete(`/invoices/${invoiceId}/notes/${noteId}`);
      const index = this.paginatedInvoices.results.findIndex(invoice => invoice._id === invoiceId);
      if (index > -1) {
        this.paginatedInvoices.results[index].notes = this.paginatedInvoices.results[index].notes.filter(note => note._id !== noteId);
      }
    } catch (error) {
      console.error("Error deleting note from invoice:", error);
    }
  }

  /**
   * Update a note on an invoice
   * @param {*} invoiceId 
   * @param {*} noteId 
   * @param {*} noteData 
   */
  // Not implemented in the API
  async updateNoteOnInvoice(invoiceId, noteId, noteData) {
    try {
      const updatedInvoice = await apiHandler.put(`/invoices/${invoiceId}/notes/${noteId}`, noteData);
      const index = this.paginatedInvoices.results.findIndex(invoice => invoice._id === invoiceId);
      if (index > -1) {
        this.paginatedInvoices.results[index] = updatedInvoice;
      }
    } catch (error) {
      console.error("Error updating note on invoice:", error);
    }
  }

  /**
   * Get all notes for an invoice
   * Notes should already be returned in get Invoice call
   * @param {*} invoiceId
   */
  async fetchNotesForInvoice(invoiceId) {
    try {
      const notes = await apiHandler.get(`/invoices/${invoiceId}/notes`);
      return notes;
    } catch (error) {
      console.error("Error fetching notes for invoice:", error);
    }
  }

  async fetchTotalIncome(startDate, endDate) {
    try {
      const response = await apiHandler.get(`/invoices/total-income?rangeType=custom&customStart=${startDate}&customEnd=${endDate}`);
      if (response?.totalIncome?.$numberDecimal) {
        this.totalIncome = response.totalIncome.$numberDecimal;
      } else {
        this.totalIncome = response.totalIncome;
      }
      return response;
    } catch (error) {
      console.error("Error fetching total income:", error);
      throw error;
    }
  }

};

export default new InvoiceStore();