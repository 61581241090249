import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AccountCodeStore from '../../stores/AccountCodeStore';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

const AccountCodeDetails = () => {
  const { accountCodeId } = useParams();
  const [accountCode, setAccountCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountCode = async () => {
      try {
        const fetchedAccountCode = await AccountCodeStore.fetchAccountCodeById(accountCodeId);
        setAccountCode(fetchedAccountCode);
      } catch (error) {
        console.error('Error fetching account code:', error);
      } finally {
        setLoading(false);
      }
    };

    if (accountCodeId) {
      fetchAccountCode();
    }
  }, [accountCodeId]);

  const handleBack = () => {
    navigate('/account-codes');
  };

  const handleEdit = () => {
    navigate(`/account-codes/edit/${accountCodeId}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!accountCode) {
    return (
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <Typography variant="h5">Account Code not found</Typography>
        <Button variant="outlined" onClick={handleBack}>
          Back to Account Codes
        </Button>
      </Box>
    );
  }

  const renderDetail = (label, value) => (
    <ListItem>
      <ListItemText primary={label} secondary={value || 'N/A'} />
    </ListItem>
  );

  return (
    <Box sx={{ p: 3 }}>
      {/* Header with Back and Edit buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ ml: 2 }}>
            Account Code Details
          </Typography>
        </Box>
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        {/* General Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            General Information
          </Typography>
          {renderDetail('Account Code', accountCode.accountCode)}
          {renderDetail('Account Base', accountCode.accountBase)}
          {renderDetail('Description', accountCode.accountDescription)}
          {renderDetail('Type', accountCode.accountType)}
          {renderDetail('Increment', accountCode.accountIncr)}
          {renderDetail('Tax Line', accountCode.taxLine)}
          {renderDetail('Website', accountCode.website)}
          {renderDetail('Contact', accountCode.contact)}

          <Divider sx={{ my: 2 }} />

          {renderDetail('Comments', accountCode.comments)}
        </Grid>

        {/* Status Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Status
          </Typography>
          {renderDetail('Active', accountCode.isActive ? 'Yes' : 'No')}
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <Button variant="contained" onClick={handleEdit}>
          Edit
        </Button>
        <Button variant="outlined" onClick={handleBack}>
          Back to Account Codes
        </Button>
      </Box>
    </Box>
  );
};

export default AccountCodeDetails;
