import React from 'react';
import { Box, TextField, Grid, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';

const AccountCodeForm = ({ accountCode, onSubmit, onChange, back }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...accountCode, [name]: value });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>Account Information</Typography>
          <TextField fullWidth name="accountCode" label="Account Code" value={accountCode.accountCode} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="accountBase" label="Account Base" value={accountCode.accountBase} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="accountDescription" label="Description" value={accountCode.accountDescription} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="accountIncr" label="Increment" value={accountCode.accountIncr} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="accountType" label="Account Type" value={accountCode.accountType} onChange={handleChange} margin="normal" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>Additional Information</Typography>
          <TextField fullWidth name="taxLine" label="Tax Line" value={accountCode.taxLine} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="website" label="Website" value={accountCode.website} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="contact" label="Contact" value={accountCode.contact} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="comments" label="Comments" value={accountCode.comments} onChange={handleChange} margin="normal" />
          <FormControlLabel
            control={<Checkbox checked={accountCode.isActive} onChange={(e) => onChange({ ...accountCode, isActive: e.target.checked })} />}
            label="Active"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={back}>Back</Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
      </Box>
    </Box>
  );
};

export default AccountCodeForm;
