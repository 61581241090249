import React from 'react';
import Typography from '@mui/material/Typography';

const PrivacyPage = () => {
  return (
    <div>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" gutterBottom>
        At Buckeye Hone, your privacy is a top priority. This Privacy Policy outlines what personal information we collect, why we collect it, how we use it, and the steps we take to ensure your information is handled with care.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Information We Collect
      </Typography>
      <Typography variant="body1" gutterBottom>
        We collect personal information that is essential for the effective operation of our HR platform. This information is primarily used to manage employment processes and provide you with a seamless HR experience. The types of personal information we may collect include:
        <ul>
          <li>Your full name</li>
          <li>Contact information such as email address, home address, and phone number</li>
          <li>Employment-related data, including your job title, department, and employment status</li>
          <li>Data related to performance reviews, payroll, and benefits</li>
          <li>Other information you voluntarily provide to us through our platform</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        The information we collect is used solely for purposes related to your employment and the operation of our HR platform. Specifically, we use your data for:
        <ul>
          <li>Managing employee records and HR processes, including payroll and benefits</li>
          <li>Maintaining accurate and up-to-date employment records</li>
          <li>Complying with legal and regulatory requirements</li>
          <li>Improving our platform and HR services based on feedback and usage patterns</li>
        </ul>
        Your data is never shared with third-party organizations for commercial purposes. We will not disclose your personal information to anyone outside of the company without your explicit consent, except where required by law.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Data Security
      </Typography>
      <Typography variant="body1" gutterBottom>
        We take the security of your personal information seriously. We use industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes encryption, secure data storage, and regular security audits.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Your Rights
      </Typography>
      <Typography variant="body1" gutterBottom>
        You have the right to access, update, or request the deletion of your personal information at any time. If you would like to review the information we hold about you or make changes, please contact us using the details below.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you have any questions, concerns, or requests regarding our privacy practices or the data we hold about you, please reach out to us at support@buckeyehone.com. We are committed to addressing any concerns promptly and ensuring your data is handled responsibly.
      </Typography>
    </div>
  );
};

export default PrivacyPage;
