import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Select, MenuItem, FormControl,
  Typography
} from '@mui/material';
import PlugReport from './PlugReport';
import DeliveriesReport from './DeliveriesReport';
import OpenMasterReport from './OpenMasterReport';
import DaysInProcessReport from './DaysInProcessReport';
import ClosedMasterReport from './ClosedMasterReport';
import ProductionListReport from './ProductionListReport';
import ProductionFootageReport from './ProductionFootageReport';

const Products = () => {
  const [report, setReport] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const reportParam = searchParams.get('report');

    switch (reportParam) {
      case 'productionList':
        setReport(0);
        break;
      case 'footage':
        setReport(1);
        break;
      case 'plug':
        setReport(2);
        break;
      case 'deliveries':
        setReport(3);
        break;
      case 'openMaster':
        setReport(4);
        break;
      case 'daysInProcess':
        setReport(5);
        break;
      case 'closedMaster':
        setReport(6);
        break;
      default:
        setReport(0); 
    }
  }, [location.search]); 

  const handleReportChange = (event) => {
    const selectedReport = event.target.value;
    setReport(selectedReport);

    const reportNames = [
      'productionList',
      'footage',
      'plug',
      'deliveries',
      'openMaster',
      'daysInProcess',
      'closedMaster'
    ];

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('report', reportNames[selectedReport]);
    
    navigate(`?${searchParams.toString()}`);
  };

  const renderReport = () => {
    switch (report) {
      case 0:
        return <ProductionListReport />;
      case 1: 
        return <ProductionFootageReport />;
      case 2:
        return <PlugReport />;
      case 3:
        return <DeliveriesReport />;
      case 4:
        return <OpenMasterReport />;
      case 5:
        return <DaysInProcessReport />;
      case 6:
        return <ClosedMasterReport />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <Typography variant='caption' sx={{ ml: 2, my: 1 }}>Select Report</Typography>
          <Select
            labelId="product-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Production List</MenuItem>
            <MenuItem value={1}>Footage</MenuItem>
            <MenuItem value={2}>Plugs</MenuItem>
            <MenuItem value={3}>Deliveries</MenuItem>
            <MenuItem value={4}>Open Master</MenuItem>
            <MenuItem value={5}>Days in Process</MenuItem>
            <MenuItem value={6}>Closed Master</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Products;