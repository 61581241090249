import { useState, useEffect } from 'react';
import { Grid, TextField, Autocomplete, Button, Modal, Box, Typography, CircularProgress } from '@mui/material';
import MachineStore from '../../stores/MachineStore';
import { debounce } from 'lodash';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

/**
 * Component for searching for a machine with autocomplete
 * and adding a new machine.
 * MUST supply machines array
 * @param {number} margin - margin for the component
 * @param {function} handleChange - function to handle change of machine
 * @param {array} machines - array of machines
 * @param {string} value - value of the machine
 * @returns  
 */
const MachineSearch = ({ margin = 0, handleChange, label = "Search Machines", initialMachineId, marginTop }) => {

  const [machines, setMachines] = useState({
    results: [],
    totalCount: 0
  });
  const [initMachine, setInitMachine] = useState(null);
  const [open, setOpen] = useState(false);
  const loading = open && machines.totalCount === 0;

  const handleInputChange = debounce((event, value, reason) => {
    const numOptions = machines.results.filter((machine) =>
      machine.name.toLowerCase().includes(value.toLowerCase())
    );
    if (value.length > 0 && numOptions.length < 10 && reason !== 'reset') {
      //Search for machines with the search value
      const filter = {
        $or: [
          { machineId: { $regex: value } },
          { name: { $regex: value } },
          { operators: { $regex: value } }
        ]
      }
      getPaginatedMachines(1, 20, 'name', filter, '', '')
    }
  });

  /**
  * Example filter:
  * {
  *   "$or": [
  *     {"machineId": { "$regex": "e" }}, 
  *     {"name": { "$regex": "e"}}, 
  *     {"operators": { "$regex": "e"}}
  *   ]
  * }
  */

  const getPaginatedMachines = async (page, limit, sort, filters, search, searchBy) => {
    try {
      await MachineStore.fetchPaginatedMachines(page, limit, sort, filters, search, searchBy);
      setMachines(MachineStore.paginatedMachines);
    } catch (error) {
      console.error('Failed to get machines:', error);
    }
  };

  useEffect(() => {
    let active = true;

    let page = 1;
    let pageSize = 20;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        await getPaginatedMachines(page, pageSize, 'name', {}, '', 'name');
        if (active) {
          setMachines(MachineStore.paginatedMachines);
        }
      } catch (error) {
        console.error('Failed to fetch machines:', error);
      }
    })();

  }, [loading]);

  useEffect(() => {
    if (!open) {
      setMachines({
        results: [],
        totalCount: 0
      });
    }
  }, [open]);

  return (
    <Grid>
      {machines ? (
        <>
          <Grid item xs={12}>
            {initMachine ? (
              <Autocomplete
                disablePortal
                fullWidth
                id="machine"
                getOptionLabel={(option) => `${option.name} / ${option.operators.join(", ")}`}
                options={machines.results}
                loading={loading}
                onChange={(event, value) => {
                  handleChange(value);
                }}
                onInputChange={handleInputChange}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
                value={initMachine}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <Autocomplete
                disablePortal
                fullWidth
                id="machine"
                getOptionLabel={(option) => `${option.name} / ${option.operators.join(", ")}`}
                options={machines.results}
                loading={loading}
                onChange={(event, value) => {
                  handleChange(value);
                }}
                onInputChange={handleInputChange}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}

          </Grid>
        </>
      ) : (
        <>
          Loading Machines...
        </>
      )}
    </Grid>
  )
};

export default MachineSearch;