import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams, Link } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Paper, Divider, Grid, Button,
  Snackbar, Alert, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, TablePagination, Tooltip, Container, Stack, Avatar, Card, CardContent
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import CustomerStore from '../../stores/CustomerStore';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import { useNavigate } from 'react-router-dom';

const formatAddress = (address) => {
  const { street, line2, city, state, postalCode } = address || {};
  return [street, line2, city, state, postalCode].filter(Boolean).join(', ');
};

const CustomerDetails = observer(() => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const fetchedCustomer = await CustomerStore.fetchCustomerById(customerId);
        setCustomer(fetchedCustomer);
      } catch (error) {
        console.error('Error fetching customer:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCustomerOrders = async () => {
      setIsLoading(true);

      try {
        await ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, '-enteredDate', { customer: customerId });
        setOrders(ShopOrdersStore.paginatedShopOrders);
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to fetch orders', severity: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomer();
    fetchCustomerOrders();
  }, [customerId, page, pageSize]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleViewOrder = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!customer) {
    return (
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <Typography variant="h5">Customer not found</Typography>
        <Link to="/customers" style={{ textDecoration: 'none' }}>
          <Typography variant="body1" color="primary">
            Go back to Customer List
          </Typography>
        </Link>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IconButton component={Link} to="/customers">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">Customer Details</Typography>
          </Box>
          <IconButton component={Link} to={`/customer/edit/${customerId}`}>
            <EditIcon />
          </IconButton>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ width: 56, height: 56 }}>{customer.companyName[0]}</Avatar>
                  <Box>
                    <Typography variant="h6">{customer.companyName}</Typography>
                    <Typography variant="body2" color="textSecondary">{customer.companyEmail}</Typography>
                    <Typography variant="body2" color="textSecondary">{customer.companyPhone}</Typography>
                  </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="textSecondary">Company ID: {customer.companyId}</Typography>
                <Typography variant="body2" color="textSecondary">Abbreviation: {customer.companyAbbreviation}</Typography>
                <Typography variant="body2" color="textSecondary">Fax: {customer.companyFax}</Typography>
                <Typography variant="body2" color="textSecondary">Website: {customer.website}</Typography>
              </CardContent>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <CardContent>
                <Typography variant="h6" gutterBottom>Billing and Shipping</Typography>
                <Typography variant="body2" color="textSecondary">Billing Address: {formatAddress(customer.billingAddress)}</Typography>
                <Typography variant="body2" color="textSecondary">Shipping Address: {formatAddress(customer.shippingAddress)}</Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="textSecondary">Discount: {customer.discount}%</Typography>
                <Typography variant="body2" color="textSecondary">Billing Terms: {customer.discountDays} days, Net {customer.billingTerms.netDays}</Typography>
              </CardContent>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h5" gutterBottom>Customer Orders</Typography>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper elevation={3} sx={{ p: 4 }}>
          {orders.results.length > 0 ? (
            <>
              <List>
                {orders.results.map((order) => (
                  <ListItem
                    key={order._id}
                    button
                    onClick={() => handleViewOrder(order._id)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2,
                      border: '1px solid #e0e0e0',
                      borderRadius: 2,
                      p: 2
                    }}
                  >
                    <Box>
                      <Typography variant="body1" fontWeight="bold">Order #{order.salesOrderNumber}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Date Entered: {format(new Date(order.enteredDate), 'MM/dd/yyyy')} | Due: {format(new Date(order.dueDate), 'MM/dd/yyyy')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">Total: ${order.total}</Typography>
                    </Box>
                    <Box>
                      <Tooltip title="View Order">
                        <IconButton onClick={() => handleViewOrder(order._id)}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Order">
                        <IconButton onClick={() => handleViewOrder(order._id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                ))}
              </List>

              <TablePagination
                component="div"
                count={orders.totalCount}
                page={orders.currentPage > 0 ? orders.currentPage - 1 : 0}
                onPageChange={handlePageChange}
                rowsPerPage={orders.pageSize}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </>
          ) : (
            <Typography variant="body2" color="textSecondary">No orders available for this customer.</Typography>
          )}
        </Paper>
      )}

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
});

export default CustomerDetails;
