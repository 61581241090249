import { Document, Page, Text, View } from '@react-pdf/renderer';
import { htmlToText } from 'html-to-text';
import { Font } from '@react-pdf/renderer';

const styles = {
  page: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  htmlView: {
    marginTop: 20,
    width: '100%',
    padding: 10,
  },
  text: {
    fontSize: 12,
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Courier'
  },
  underline: {
    textDecoration: 'underline',
  },
  italic: {
    fontStyle: 'italic',
    fontFamily: 'Courier'
  },
  indent: {
    marginLeft: 10,
  },
  titleSection: {
    width: '100%',
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 8,
    paddingTop: 8,
    paddingRight: 8,
  },
  subtitleSection: {
    width: '100%',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
  },
  simpleCell: {
    border: 1,
    width: '30%',
    textAlign: 'center',
    margin: -0.5,
    padding: 2,
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    fontSize: 10,
    padding: 8,
    marginTop: 32,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    border: 1,
    margin: -0.5,
    flexGrow: 1,
  },
  tableCell: {
    borderLeft: 1,
    padding: 2,
    flexGrow: 1,
  },
};

const renderFormattedText = (htmlContent) => {
  console.log(htmlContent);
  const tags = /(<b>|<\/b>|<i>|<\/i>|<u>|<\/u>|<br>|<\/strong>|<strong>)/g;
  return htmlContent.split(tags).map((chunk, index) => {
    switch (chunk) {
      case '<strong>':
      case '<b>':
        return <Text key={index} fontFamily='Helvetica-Bold' fontStyle='' style={styles.bold} />;
      case '</strong>':
      case '</b>':
        return null;
      case '<i>':
        return <Text key={index} style={styles.italic} />;
      case '</i>':
        return null;
      case '<u>':
        return <Text key={index} style={styles.underline} />;
      case '</u>':
        return null;
      case '<br>':
        return <Text key={index}>{'\n'}</Text>;
      default:
        return <Text key={index}>{chunk}</Text>;
    }
  });
};

const convertHtmlToText = (htmlContent) => {
  console.log(htmlContent);
  return htmlToText(htmlContent, {
    wordwrap: 130,
    formatters: {
      bold: (elem, walk, builder) => {
        builder.addInline('<b>');
        walk(elem.children, builder);
        builder.addInline('</b>');
      },
      italic: (elem, walk, builder) => {
        builder.addInline('<i>');
        walk(elem.children, builder);
        builder.addInline('</i>');
      },
      underline: (elem, walk, builder) => {
        builder.addInline('<u>');
        walk(elem.children, builder);
        builder.addInline('</u>');
      },
    },
    selectors: [
      { selector: 'b', format: 'bold' },
      { selector: 'strong', format: 'bold' },
      { selector: 'i', format: 'italic' },
      { selector: 'u', format: 'underline' },
    ],
  });
};

const InvoicePDF = ({ invoice }) => {
  const finalTotal = invoice.extraCharges.reduce((total, charge) => {
    if (charge.type === 'Flat') {
      return total + charge.amount;
    } else if (charge.type === 'Percentage') {
      return total + (total * (charge.amount / 100));
    }
    return total;
  }, invoice.totalAmount);

  const formattedComments = convertHtmlToText(invoice.additionalComments);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleSection}>
          <Text style={{ flex: 1 }}>Buckeye Hone Company</Text>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flex: 1 }}>
            <Text>Invoice: {invoice.invoiceNumber}</Text>
          </View>
        </View>
        <View style={styles.subtitleSection}>
          <View style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 4 }}>
            <Text>PO Box 72</Text>
            <Text>Mercer, PA 16137-0072</Text>
            <Text style={{ height: 16 }} />
            <Text>(724) 748-3008</Text>
            <Text>Fed ID#:25-1366564</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
              <Text style={[styles.simpleCell, { width: '30%' }]}>Invoice Date</Text>
              <Text style={[styles.simpleCell, { width: '40%' }]}>Your Order No</Text>
              <Text style={[styles.simpleCell, { width: '30%' }]}>Our Order No</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.simpleCell, { width: '30%' }]}>
                {new Date(invoice.invoiceDate).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }) || 'N/A'}
              </Text>
              <Text style={[styles.simpleCell, { width: '40%' }]}>{invoice.customerPONumber || 'N/A'}</Text>
              <Text style={[styles.simpleCell, { width: '30%' }]}>{invoice.salesOrderNumber || 'N/A'}</Text>
            </View>
            <Text style={{ display: 'flex', textAlign: 'center', marginTop: 16 }}>{invoice.invoiceCode}</Text>
          </View>
        </View>

        <View style={styles.subtitleSection}>
          <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <Text style={{ flex: 1 }}>Sold To:</Text>
            <View style={{ display: 'flex', flexDirection: 'column', gap: 4, flex: 5 }}>
              <Text>{invoice.customerName}</Text>
              <Text>{invoice.billingAddress.street}</Text>
              <Text>{invoice.billingAddress.line2}</Text>
              <Text>
                {invoice.billingAddress.city}, {invoice.billingAddress.state} {invoice.billingAddress.postalCode}
              </Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <Text style={{ flex: 1 }}>Ship To:</Text>
            <View style={{ display: 'flex', flexDirection: 'column', gap: 4, flex: 5 }}>
              <Text>{invoice.shippingAddress.name}</Text>
              <Text>{invoice.shippingAddress.street}</Text>
              <Text>
                {invoice.shippingAddress.city}, {invoice.shippingAddress.state} {invoice.shippingAddress.postalCode}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={{ flex: 1.5, padding: 2 }}>
              Terms: {invoice.customer.discountPercentage}% {invoice.customer.discountDays} Days, Net {invoice.customer.netDays}
            </Text>
            <Text style={{ flex: 1, padding: 2 }}>Ship Via:</Text>
            <Text style={{ flex: 1, padding: 2 }}>FOB: Mercer, PA</Text>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { width: '10%', borderLeft: 0 }]}></View>
            <Text style={[styles.tableCell, { width: '90%' }]}>Rep:</Text>
          </View>
          <View style={[styles.tableRow, { backgroundColor: '#c0c0c0' }]}>
            <Text style={[styles.tableCell, { width: '10%', textAlign: 'center', borderLeft: 0 }]}>Quantity</Text>
            <Text style={[styles.tableCell, { width: '50%' }]}>Description</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>Unit Price</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>Total</Text>
          </View>

          <View style={[styles.tableRow, { flexWrap: 'wrap' }]}>
            <View style={[styles.tableCell, { width: '10%', display: 'flex', flexDirection: 'column', borderLeft: 0 }]}>
              <Text style={{ textAlign: 'center' }}>{invoice.feet}'</Text>
            </View>
            <View style={[styles.tableCell, { width: '50%', display: 'flex', flexDirection: 'column' }]}>
              <Text style={{ fontSize: 10 }}>
                {invoice.outerHone} X {invoice.innerHone} TUBES HONED {invoice.orderComments ? `- ${invoice.orderComments}` : ''}
              </Text>
            </View>
            <View style={[styles.tableCell, { width: '20%', display: 'flex', flexDirection: 'column' }]}>
              <Text style={{ textAlign: 'center' }}>${invoice.costPerFoot} per ft.</Text>
              <Text style={{ height: 24 }} />
              <Text style={{ textAlign: 'center' }}>{invoice.additionalChargeDescription}</Text>
              {invoice.extraCharges.map((charge, index) => (
                <Text key={index} style={{ marginTop: 2, textAlign: 'center' }}>
                  {charge.description}
                  {charge.type === 'Percentage' ? ` (${charge.amount}%)` : ''}
                </Text>
              ))}
            </View>
            <View style={[styles.tableCell, { width: '20%', display: 'flex', flexDirection: 'column' }]}>
              <Text style={{ textAlign: 'right' }}>${(invoice.costPerFoot * invoice.feet).toFixed(2) || 'N/A'}</Text>
              <Text style={{ height: 24 }} />
              <Text style={{ textAlign: 'right' }}>${invoice.additionalCharges.toFixed(2)}</Text>
              {invoice.extraCharges.map((charge, index) => {
                let cumulativeTotal = invoice.totalAmount;
                for (let i = 0; i < index; i++) {
                  const prevCharge = invoice.extraCharges[i];
                  if (prevCharge.type === 'Flat') {
                    cumulativeTotal += prevCharge.amount;
                  } else if (prevCharge.type === 'Percentage') {
                    cumulativeTotal += (cumulativeTotal * (prevCharge.amount / 100));
                  }
                }
                const displayAmount = charge.type === 'Percentage'
                  ? (cumulativeTotal * (charge.amount / 100)).toFixed(2)
                  : charge.amount?.toFixed(2) || '0.00';
                if (charge.type === 'Flat') {
                  cumulativeTotal += charge.amount;
                } else if (charge.type === 'Percentage') {
                  cumulativeTotal += (cumulativeTotal * (charge.amount / 100));
                }
                return (
                  <Text key={index} style={{ marginTop: 2, textAlign: 'right' }}>
                    ${displayAmount}
                  </Text>
                );
              })}
            </View>
          </View>
          <View style={[styles.tableRow, { height: 16 }]}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0 }]} />
            <Text style={[styles.tableCell, { width: '50%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>

          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0 }]} />
            <Text style={[styles.tableCell, { width: '50%', textAlign: 'center' }]}>Mill No: {invoice.millNo}</Text>
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>

          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0 }]} />
            <Text style={[styles.tableCell, { width: '30%', textAlign: 'center' }]}>Bundles: {invoice.bundles}</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>OAL: {invoice.oal}</Text>
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>

          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '10%', borderLeft: 0 }]} />
            <Text style={[styles.tableCell, { width: '30%', textAlign: 'center' }]}>Pieces: {invoice.pieces}</Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'center' }]}>Weight: {invoice.weight}#</Text>
            <Text style={[styles.tableCell, { width: '20%' }]} />
            <Text style={[styles.tableCell, { width: '20%' }]} />
          </View>

          <View style={[styles.tableRow, { height: 16 }]} />

          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { width: '60%', borderLeft: 0 }]} />
            <Text style={[styles.tableCell, { width: '20%' }]}>Total Due: </Text>
            <Text style={[styles.tableCell, { width: '20%', textAlign: 'right' }]}>${finalTotal.toFixed(2)}</Text>
          </View>
        </View>

        <View style={styles.htmlView}>
          <Text style={styles.text}>{renderFormattedText(formattedComments)}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
