import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import VendorStore from '../../stores/VendorStore';
import PayablesStore from '../../stores/PayablesStore';
import {
  Box, Typography, CircularProgress, Paper, Divider, Grid, List, ListItem, ListItemText, ListItemSecondaryAction,
  TablePagination, IconButton, Tooltip, Snackbar, Alert, Container, Stack, Avatar, Card, CardContent, Button, Chip, ToggleButton, ToggleButtonGroup, Modal,
  ListItemAvatar, Badge
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { format, isValid } from 'date-fns';
import { PDFViewer } from '@react-pdf/renderer';
import CheckPDF from '../../pdf/CheckPDF';
import EmptyStateIcon from '@mui/icons-material/Square';
import MoneyIcon from '@mui/icons-material/Money';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { formatCurrency } from '../../utils/numberFormatting';

const VendorDetails = () => {
  const { vendorId } = useParams();
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payables, setPayables] = useState({
    results: [],
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const [viewPdf, setViewPdf] = useState(false);
  const [checkData, setCheckData] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendor = async () => {
      try {
        const fetchedVendor = await VendorStore.fetchVendorById(vendorId);
        setVendor(fetchedVendor);
        fetchVendorPayables();
      } catch (error) {
        console.error('Error fetching vendor:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchVendorPayables = async () => {
      try {
        const filter = {};
        if (filterType === 'unpaid') {
          filter.paidAmount = { $eq: 0 };
        } else if (filterType === 'paid') {
          filter.paidAmount = { $gt: 0 };
        }

        await PayablesStore.fetchPaginatedPayables(page, pageSize, '-dueDate', { vendor: vendorId, ...filter });
        setPayables(PayablesStore.paginatedPayables);
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to fetch payables', severity: 'error' });
      }
    };

    fetchVendor();
  }, [vendorId, page, pageSize, filterType]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleViewPayable = (payableId) => {
    navigate(`/payable/${payableId}`);
  };

  const handleEditPayable = () => {
    if (selectedInvoices.length === 1) {
      navigate(`/payable/edit/${selectedInvoices[0]}`);
    }
  };

  const handleDeletePayable = () => {
    // Implement delete logic
  };

  const handlePay = () => {
    // Implement pay logic
  };

  const formatDate = (date) => {
    const parsedDate = new Date(date);
    return isValid(parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : 'Invalid date';
  };


  const handlePrintPayable = () => {
    const selectedPayables = payables.results.filter((payable) => selectedInvoices.includes(payable._id));
    const combinedPayables = selectedPayables.reduce((acc, curr) => {
      acc.amount += curr.invoiceAmount;
      acc.text += `Invoice No: ${curr.invoiceNo}, Amount: $${curr.invoiceAmount.toFixed(2)}\n`;
      return acc;
    }, { amount: 0, text: '' });

    const today = new Date();
    const formattedDate = Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).format(today);

    const data = {
      checkNumber: 'Combined Check',
      date: formattedDate,
      payee: selectedPayables[0]?.vendor.vendorName,
      amount: combinedPayables.amount,
      text: combinedPayables.text
    };
    setCheckData(data);
    setViewPdf(true);
  };

  const handleFilterChange = (event, newFilterType) => {
    if (newFilterType !== null) {
      setFilterType(newFilterType);
    }
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedInvoices(selectionModel);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!vendor) {
    return (
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <Typography variant="h5">Vendor not found</Typography>
        <Link to="/vendors" style={{ textDecoration: 'none' }}>
          <Typography variant="body1" color="primary">
            Go back to Vendor List
          </Typography>
        </Link>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IconButton component={Link} to="/vendors">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">Vendor Details</Typography>
          </Box>
          <IconButton component={Link} to={`/vendor/edit/${vendorId}`}>
            <EditIcon />
          </IconButton>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ width: 56, height: 56 }}>{vendor.vendorName ? vendor.vendorName[0] : 'N/A'}</Avatar>
                  <Box>
                    <Typography variant="h6">{vendor.vendorName || 'Vendor Name Not Available'}</Typography>
                    {vendor.email && <Typography variant="body2" color="textSecondary">{vendor.email}</Typography>}
                    {vendor.phone && <Typography variant="body2" color="textSecondary">{vendor.phone}</Typography>}
                  </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />
                {vendor.vendorId && <Typography variant="body2" color="textSecondary">Vendor ID: {vendor.vendorId}</Typography>}
                {vendor.federalId && <Typography variant="body2" color="textSecondary">Federal ID: {vendor.federalId}</Typography>}
                {vendor.salesId && <Typography variant="body2" color="textSecondary">Sales ID: {vendor.salesId}</Typography>}
              </CardContent>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <CardContent>
                <Typography variant="h6" gutterBottom>Address Information</Typography>
                {vendor.address && Object.values(vendor.address).some(val => val) ? (
                  <Typography variant="body2" color="textSecondary">
                    Address: {[vendor.address.street, vendor.address.city, vendor.address.state, vendor.address.postalCode, vendor.address.country]
                      .filter(Boolean).join(', ')}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textSecondary">Address not available</Typography>
                )}

                {vendor.paymentAddress && Object.values(vendor.paymentAddress).some(val => val) ? (
                  <Typography variant="body2" color="textSecondary">
                    Payment Address: {[vendor.paymentAddress.street, vendor.paymentAddress.city, vendor.paymentAddress.state, vendor.paymentAddress.postalCode, vendor.paymentAddress.country]
                      .filter(Boolean).join(', ')}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textSecondary" sx={{ color: 'gray' }}>No payment address provided</Typography>
                )}

                <Divider sx={{ my: 2 }} />
                {vendor.glAcct && <Typography variant="body2" color="textSecondary">GL Account: {vendor.glAcct}</Typography>}
                {vendor.payments !== undefined && <Typography variant="body2" color="textSecondary">Payments: {vendor.payments}</Typography>}
                {vendor.purchases !== undefined && <Typography variant="body2" color="textSecondary">Purchases: {vendor.purchases}</Typography>}
              </CardContent>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h5" gutterBottom>Vendor Payables</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <ToggleButtonGroup
          value={filterType}
          exclusive
          onChange={handleFilterChange}
          aria-label="invoice filter"
        >
          <ToggleButton value="all" aria-label="All Invoices">
            All
          </ToggleButton>
          <ToggleButton value="unpaid" aria-label="Unpaid Invoices">
            Unpaid
          </ToggleButton>
          <ToggleButton value="paid" aria-label="Paid Invoices">
            Paid
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {viewPdf && (
        <Modal
          open={viewPdf}
          onClose={() => setViewPdf(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
            <IconButton onClick={() => setViewPdf(false)} sx={{ position: 'absolute', right: 0, top: 0 }}>
              <CloseIcon />
            </IconButton>
            <PDFViewer width="100%" height="100%">
              <CheckPDF report={checkData} />
            </PDFViewer>
          </Paper>
        </Modal>
      )}

      <Paper elevation={3} sx={{ p: 4 }}>
        <List>
          {payables.results.length === 0 ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <EmptyStateIcon color="action" fontSize="large" />
              <Typography variant="h6" color="textSecondary">
                No payables available.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Once invoices are created, they will appear here.
              </Typography>
            </Box>
          ) : (
            payables.results.map((payable) => (
              <ListItem
                key={payable?._id || Math.random()} // Use fallback if _id is missing
                button
                onClick={() => handleViewPayable(payable._id)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: "8px",
                  transition: "background-color 0.3s",
                  mb: 2,
                  border: '1px solid #e0e0e0',
                  p: 2,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)"
                  }
                }}
              >
                <ListItemAvatar>
                  <Badge
                    badgeContent={formatCurrency(payable?.invoiceAmount - payable?.paidAmount || 0) === "$0.00" ? "Paid" : "Due"}
                    color={formatCurrency(payable?.invoiceAmount - payable?.paidAmount || 0) === "$0.00" ? "success" : "error"}
                    overlap="circular"
                  >
                    <Avatar>
                      <MoneyIcon />
                    </Avatar>
                  </Badge>
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <Typography variant="subtitle1" fontWeight="bold">
                      {payable?.vendor?.vendorName || "Vendor not available"}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary">
                        Invoice No: {payable?.invoiceNo || "N/A"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Due Date: {payable?.dueDate ? formatDate(payable.dueDate) : "N/A"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Total Amount: {payable?.invoiceAmount ? formatCurrency(payable.invoiceAmount) : "N/A"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Unpaid Amount: {payable?.invoiceAmount && payable?.paidAmount ? formatCurrency(payable.invoiceAmount - payable.paidAmount) : "N/A"}
                      </Typography>
                    </>
                  }
                />

                <Chip
                  label={
                    payable?.paidAmount >= payable?.invoiceAmount
                      ? "Paid"
                      : payable?.paidAmount > 0
                        ? "Partially Paid"
                        : "Unpaid"
                  }
                  color={
                    payable?.paidAmount >= payable?.invoiceAmount
                      ? "success"
                      : payable?.paidAmount > 0
                        ? "warning"
                        : "error"
                  }
                  variant="outlined"
                />

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Tooltip title="View Payable">
                    <IconButton onClick={() => handleViewPayable(payable._id)}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Payable">
                    <IconButton onClick={() => handleViewPayable(payable._id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton edge="end" aria-label="edit">
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))
          )}
        </List>


        <TablePagination
          component="div"
          count={payables.totalCount}
          page={payables.currentPage > 0 ? payables.currentPage - 1 : 0}
          onPageChange={handlePageChange}
          rowsPerPage={payables.pageSize}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default VendorDetails;
