import { useState, useEffect } from "react";
import { Grid, TextField, Autocomplete, CircularProgress } from "@mui/material";
import VendorStore from "../../stores/VendorStore";
import { debounce } from "lodash";

/**
 * Component for searching for a vendor with autocomplete
 * MUST supply vendors array
 * @param {number} margin - margin for the component
 * @param {function} handleChange - function to handle change of vendor
 * @param {array} vendors - array of vendors
 * @param {string} value - value of the vendor
 * @returns  
 */
const VendorSearch = ({ margin = 0, handleChange, marginTop, label = "Search Vendors", showInactive = false }) => {

  const [vendors, setVendors] = useState({
    results: [],
    totalCount: 0,
  });
  const [open, setOpen] = useState(false);
  const loading = open && vendors.totalCount === 0;

  // Search for vendors every 500ms with given search value
  const handleInputChange = debounce((event, value, reason) => {
    const numOptions = vendors.results.filter((vendor) =>
      vendor.vendorName.toLowerCase().includes(value.toLowerCase())
    );
    if (value.length > 0 && numOptions.length < 10) {
      getPaginatedVendors(1, 20, 'name', {}, value, 'vendorName');
    }
  }, 500);

  const getPaginatedVendors = async (page, limit, sort, filters, search, searchBy) => {
    const activeFilter = {
      ...filters,
      active: true
    }
    const usedFilter = showInactive ? filters : activeFilter
    try {
      await VendorStore.fetchPaginatedVendors(page, limit, sort, usedFilter, search, searchBy);
      setVendors(VendorStore.paginatedVendors);
    } catch (error) {
      console.error('Failed to fetch vendors:', error);
    }
  };

  useEffect(() => {
    let active = true;

    let page = 1;
    let pageSize = 20;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        await getPaginatedVendors(
          page,
          pageSize,
          'name',
        );
        if (active) {
          setVendors(VendorStore.paginatedVendors);
        }
      } catch (error) {
        console.error('Failed to fetch vendors:', error);
      }
    })();

    return () => {
      active = false;
    };

  }, [loading]);

  useEffect(() => {
    if (!open) {
      setVendors({
        results: [],
        totalCount: 0,
      });
    }
  }, [open]);

  return (
    <Grid container margin={margin} marginTop={(marginTop || marginTop === 0) ? marginTop : (margin + 3)}>
      {vendors ? (
        <>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              fullWidth
              id="vendor"
              getOptionLabel={(option) => option.vendorName}
              options={vendors.results}
              onChange={(event, newValue) => {
                handleChange(newValue);
              }}
              onInputChange={handleInputChange}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              loading={loading}
              isOptionEqualToValue={(option, value) => {
                return option._id === value._id;
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              }
            />
          </Grid>
        </>
      ) : (
        <>
          Loading Vendors...
        </>
      )}
    </Grid>

  )
};

export default VendorSearch;