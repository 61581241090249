import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ReceivablesStore from '../../stores/ReceivablesStore';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, IconButton, Snackbar, Alert,
  List, ListItem, ListItemText, ListItemSecondaryAction, useMediaQuery,
  TextField, Button,
  TablePagination
} from '@mui/material';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { format, isValid } from 'date-fns';
import CustomerSearch from '../../components/search/CustomerSearch';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { NumericFormat } from 'react-number-format';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InvoiceStore from '../../stores/InvoiceStore';



const Receivables = observer(() => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const [customer, setCustomer] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceFromDate, setInvoiceFromDate] = useState(null);
  const [invoiceToDate, setInvoiceToDate] = useState(null);
  const [updatedInvoices, setUpdatedInvoices] = useState([]);
  const [checkNo, setCheckNo] = useState('0');
  const [checkAmt, setCheckAmt] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchPaginatedInvoices(customer);
  }, [customer, invoiceNumber, invoiceFromDate, invoiceToDate]);

  const handleCustomerSelect = (customer) => {
    if (customer) {
      setCustomer(customer._id);
    } else {
      setCustomer(null);
    }
  };

  const fetchPaginatedInvoices = (customerId, page = 1, pageSize = 25) => {

    let filter = {
      $and: [
        {
          $expr: {
            $gt: [
              {
                $abs: {
                  $subtract: ["$amountPaid", "$totalAmount"]
                }
              },
              0.01
            ]
          }
        },
        {
          $expr: {
            $gt: [
              {
                $subtract: ["$totalAmount", "$amountPaid"]
              },
              -0.1
            ]
          }
        }
      ]
    }
    if (invoiceNumber) filter.invoiceNumber = { "$regex": invoiceNumber, "$options": "i" };
    if (invoiceFromDate || invoiceToDate) filter.invoiceDate = {};
    if (invoiceFromDate) filter.invoiceDate.$gte = invoiceFromDate;
    if (invoiceToDate) filter.invoiceDate.$lte = invoiceToDate;
    if (customerId) filter.customer = customerId;

    setLoading(true);
    setUpdatedInvoices([]);
    setCheckAmt(0);
    InvoiceStore.fetchPaginatedInvoices(page, pageSize, 'invoiceDate', filter).then(() => {
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch invoices', severity: 'error' });
      setLoading(false);
    });
  };

  const generateNewReceivableNumber = async () => {
    try {
      const { results } = await ReceivablesStore.fetchPaginatedReceivables(1, 30, '-recId', {}, '\\d{5,}', 'recId');
      const lastReceivable = results[0];
      const lastReceivableNumber = lastReceivable.recId;
      let receivableNumber = +lastReceivableNumber;

      if (receivableNumber === 99999) {
        const { results } = await ReceivablesStore.fetchPaginatedReceivables(1, 30, '-recId', {}, '\\d{6,}', 'recId');
        const lastReceivable = results[0];
        const lastReceivableNumber = lastReceivable.recId;
        receivableNumber = +lastReceivableNumber;
      }

      return receivableNumber + 1;
    } catch (error) {
      console.error("Error generating new receivable number:", error);
    }
  }

  const formatCurrency = (amount) => {
    return amount !== undefined ? `$${amount.toFixed(2)}` : 'N/A';
  };

  const formatDate = (date) => {
    const parsedDate = new Date(date);
    return isValid(parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : 'Invalid date';
  };

  const handleUpdatedInvoice = (invoiceId, payAmount) => {
    const updatedInvoice = updatedInvoices.find(invoice => invoice._id === invoiceId);
    let updated = updatedInvoices;
    if (updatedInvoice) {

      if (!payAmount || payAmount === '') payAmount = 0;

      if (payAmount === 0 || payAmount === '0' || payAmount === '0.00') {
        updated = updatedInvoices.filter(invoice => invoice._id !== invoiceId);
      } else {
        updatedInvoice.amtPaid = payAmount;
      }
    } else {
      updated = [...updatedInvoices, { _id: invoiceId, amtPaid: payAmount }];
    }

    const totalPaid = updated.reduce((acc, cur) => acc + parseFloat(cur.amtPaid), 0);
    setUpdatedInvoices(updated);
    setCheckAmt(totalPaid);
  }

  const handleSave = async () => {

    const invoices = InvoiceStore.paginatedInvoices.results;

    const updated = updatedInvoices.map(invoice => ({ _id: invoice._id, amountPaid: +invoice.amtPaid }));
    const addPrevious = updated.map(invoice => ({ _id: invoice._id, newPay: invoice.amountPaid, amountPaid: invoice.amountPaid + invoices.find(i => i._id === invoice._id).amountPaid }));
    const filterEmpty = addPrevious.filter(invoice => (invoice.amountPaid !== 0));

    if (filterEmpty.length > 0) {
      for (let i = 0; i < filterEmpty.length; i++) {
        try {
          await InvoiceStore.updateInvoice(filterEmpty[i]._id, { amountPaid: filterEmpty[i].amountPaid });

          const recId = await generateNewReceivableNumber();
          const receivable = {
            recId,
            amtPaid: filterEmpty[i].newPay,
            checkAmt: checkAmt,
            checkNo: checkNo,
            invoice: filterEmpty[i]._id
          }

          await ReceivablesStore.createReceivable(receivable);
        } catch (error) {
          setSnackbar({ open: true, message: 'Failed to update invoices', severity: 'error' });
        }
      }
    }

    if (customer) {
      fetchPaginatedInvoices(customer);
    } else {
      fetchPaginatedInvoices();
    }

    setUpdatedInvoices([]);
    setCheckAmt(0);
    setSnackbar({ open: true, message: 'Invoices updated successfully', severity: 'success' });
  };

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection);
  };

  const handleEdit = () => {
    if (selectedRows.length === 1) {
      navigate(`/receivables/edit/${selectedRows[0]}`);
    }
  }

  const handlePageChange = (event, newPage) => {
    fetchPaginatedInvoices(customer, newPage + 1);
  }

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    fetchPaginatedInvoices(customer)
  }

  const handleRowClick = (event) => {
    navigate(`/receivables/edit/${event}`);
  }

  const columns = [
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <Box
          onClick={() => handleRowClick(params.row._id)}
          className="clickable"
        >
          <Typography
            sx={{
              flex: 1,
            }}
          >
            {params.row.customer.companyName}
          </Typography>
        </Box>
      )
    },
    {
      field: 'invoiceNo',
      headerName: 'Invoice No',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Box
          onClick={() => handleRowClick(params.row._id)}
          className="clickable"
        >
          <Typography
            sx={{
              flex: 1,
            }}
          >
            {params.row.invoiceNumber}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      flex: 1,
      sortable: false,
      valueGetter: (params) => formatDate(params.row.invoiceDate)
    },
    {
      field: 'invoiceAmount',
      headerName: 'Invoice Amount',
      sortable: false,
      flex: 1,
      valueGetter: (params) => formatCurrency(params.row.totalAmount)
    },
    {
      field: 'unpaidAmount',
      headerName: 'Unpaid Amount',
      sortable: false,
      flex: 1,
      valueGetter: (params) => formatCurrency(params.row.totalAmount - params.row.amountPaid)
    },
    {
      field: 'payAmount',
      headerName: 'Pay Amount',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <TextField
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleUpdatedInvoice(params.row._id, e.target.value)}
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
            '& input': {
              padding: '8px 10px',
            },
          }}
        />
      )
    }


  ];

  const handleInvoiceClick = (invoiceId) => {
    navigate(`/receivables/edit/${invoiceId}`);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
      <Button onClick={handleEdit} variant="outlined" disabled={selectedRows.length !== 1}>
        Edit
      </Button>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          label="Check #"
          value={checkNo}
          onChange={(e) => setCheckNo(e.target.value)}
        />
        <TextField
          label="Check Amount"
          value={formatCurrency(checkAmt)}
          disabled
        />
        <Button
          variant='outlined'
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </GridToolbarContainer>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <Typography variant="h5" gutterBottom>Open Invoices</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Box sx={{ flex: 1, maxWidth: '600px', mr: 2 }}>
            <CustomerSearch
              label="Search Customers"
              handleChange={handleCustomerSelect}
              showAdd={false}
              showInactive={true}
              marginTop="0"
            />
          </Box>
          <IconButton sx={{ marginBottom: 1 }} color="primary" onClick={() => navigate('/receivables/edit/new')}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%', alignItems: 'center', mb: 2, gap: 2 }}>
          <TextField
            label="Invoice #"
            value={invoiceNumber}
            sx={{ width: '100%' }}
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
          <DatePicker
            label="Invoice Dates From"
            name='invoiceFromDate'
            value={invoiceFromDate}
            sx={{ width: '100%' }}
            onChange={(date) => setInvoiceFromDate(date)}
          />
          <DatePicker
            label="Invoice Dates To"
            name='invoiceToDate'
            value={invoiceToDate}
            sx={{ width: '100%' }}
            onChange={(date) => setInvoiceToDate(date)}
          />
        </Box>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {isLargeScreen ? (
              <DataGridPro
                rows={InvoiceStore.paginatedInvoices.results}
                columns={columns}
                pageSize={InvoiceStore.paginatedInvoices.pageSize}
                rowCount={InvoiceStore.paginatedInvoices.totalCount}
                loading={loading}
                checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSort
                onRowSelectionModelChange={(selection) => handleSelectionChange(selection)}
                autoHeight
                slots={{
                  toolbar: CustomToolbar
                }}
                getRowId={(row) => row._id}
              />
            ) : (
              <List>
                {InvoiceStore.paginatedInvoices.results.map((invoice) => (
                  <ListItem
                    key={invoice._id}
                    button
                    onClick={() => handleInvoiceClick(invoice._id)}
                  >
                    <ListItemText
                      primary={`Customer: ${invoice.customer.companyName}`}
                      secondary={`Invoice No: ${invoice.invoiceNumber} | Invoice Date: ${formatDate(invoice.invoiceDate)} | Amount: ${formatCurrency(invoice.totalAmount)} | Unpaid Amount: ${formatCurrency(invoice.totalAmount - invoice.amountPaid)}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <TablePagination
              component="div"
              count={InvoiceStore.paginatedInvoices.totalCount}
              page={InvoiceStore.paginatedInvoices.currentPage > 0 ? InvoiceStore.paginatedInvoices.currentPage - 1 : 0}
              onPageChange={handlePageChange}
              rowsPerPage={InvoiceStore.paginatedInvoices.pageSize}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </>
        )}
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </Box>
  );
});

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        defaultValue={0}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale={2}
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

export default Receivables;
