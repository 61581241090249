import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import AccountCodeStore from '../stores/AccountCodeStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import {
  Box, Typography, IconButton, CircularProgress, Snackbar, Button,
  TablePagination, useMediaQuery, Paper, List, ListItem, ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import BackIcon from '@mui/icons-material/ArrowBack'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const filterObject = {
  accountCode: '',
  accountBase: '',
  accountDescription: '',
};

const AccountCodes = observer(() => {
  const [accountCodes, setAccountCodes] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const [selectedRows, setSelectedRows] = useState([]);


  useEffect(() => {
    fetchPaginatedAccountCodes();
  }, []);

  const fetchPaginatedAccountCodes = async (page = 1, pageSize = 10, sortBy = 'accountCode', filter = filterCriteria, search = searchQuery) => {
    setLoading(true);
    AccountCodeStore.fetchPaginatedAccountCodes(page, pageSize, sortBy, filter, search).then(() => {
      setAccountCodes(AccountCodeStore.paginatedAccountCodes);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch account codes', severity: 'error' });
      setLoading(false);
    });
  };

  const handleViewAccountCode = (accountCodeId) => {
    console.log(accountCodeId);
    navigate(`/account-codes/${accountCodeId}`);
  };

  const handleEditAccountCode = (accountCodeId) => {
    navigate(`/account-codes/edit/${accountCodeId}`);
  }

  const handleCreateAccountCode = () => {
    navigate('/account-codes/add');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedAccountCodes(page + 1, accountCodes.pageSize);
  };

  const handleLimitChange = (event) => {
    fetchPaginatedAccountCodes(1, event.target.value);
  };

  const handleDynamicSearch = (search, filter, sortBy) => {
    setSearchQuery(search);
    setFilterCriteria(filter);

    let filterBuilder = {};
    if (search) {
      const searchFilter = {
        $or: [
          { acctCodeId: { $regex: search, $options: 'i' } },
          { accountCode: { $regex: search, $options: 'i' } },
          { accountBase: { $regex: search, $options: 'i' } },
        ]
      }

      filterBuilder = { ...searchFilter };
    }

    fetchPaginatedAccountCodes(1, accountCodes.pageSize, sortBy, filterBuilder, search);
  };

  const handleBack = () => {
    navigate('/payables');
  }

  const handleEdit = () => {
    if (selectedRows.length === 1) {
      navigate(`/account-codes/edit/${selectedRows[0]}`);
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button onClick={handleEdit} variant="outlined" disabled={selectedRows.length !== 1}>
        Edit
      </Button>
      {/* <Button onClick={handleDeleteOrder} startIcon={<DeleteIcon />} disabled={selectedRows.length === 0}>
        Delete
      </Button> */}
    </GridToolbarContainer>
  );

  const columns = [
    { field: 'accountCode', headerName: 'Account Code', flex: 1, sortable: false },
    { field: 'accountBase', headerName: 'Account Base', flex: 1, sortable: false },
    { field: 'accountDescription', headerName: 'Description', flex: 1, sortable: false },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 2, mb: 2 }}>
        <DynamicSearchFilter
          defaultSort="accountCode"
          onSearch={handleDynamicSearch}
          objectInterface={filterObject}
        />
        <IconButton color="primary" onClick={handleCreateAccountCode}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box>
        {
          accountCodes.totalCount !== 0 ? (
            <>
              {isLargeScreen ? (
                <DataGridPro
                  rows={accountCodes.results}
                  columns={columns}
                  pageSize={accountCodes.pageSize}
                  rowCount={accountCodes.totalCount}
                  loading={loading}
                  autoHeight
                  slots={{
                    toolbar: CustomToolbar
                  }}
                  checkboxSelection
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSort
                  getRowId={(row) => row._id}
                  onRowSelectionModelChange={(selection) => {
                    setSelectedRows(selection);
                  }}
                  onRowClick={(params) => handleViewAccountCode(params.id)}
                  editMode="row"
                  selectionModel={selectedRows}
                />
              ) : (
                <List>
                  {accountCodes.results.map((accountCode) => (
                    <ListItem
                      key={accountCode._id}
                      button
                      onClick={() => handleViewAccountCode(accountCode._id)}
                    >
                      <ListItemText
                        primary={`Account Code: ${accountCode.accountCode}`}
                        secondary={`Base: ${accountCode.accountBase}, Description: ${accountCode.accountDescription}`}
                      />
                    </ListItem>
                  ))}
                </List>

              )}
              <TablePagination
                component="div"
                count={accountCodes.totalCount}
                page={accountCodes.currentPage > 0 ? accountCodes.currentPage - 1 : 0}
                onPageChange={handlePageChange}
                rowsPerPage={accountCodes.pageSize}
                onRowsPerPageChange={handleLimitChange}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </>
          ) : (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="subtitle1">No account codes available</Typography>
            </Box>
          )
        }
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box >
  );
});

export default AccountCodes;
