import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import MuiAlert from '@mui/material/Alert';
import JobStore from '../../stores/JobStore';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Production = observer(() => {
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [pieces, setPieces] = useState(0);
  const [hours, setHours] = useState(0);
  const [orderJobs, setOrderJobs] = useState([]);

  const handleDynamicSearch = (search, filter, sortBy) => {
    ShopOrdersStore.fetchPaginatedShopOrders(1, 20, '-enteredDate', {}, search, 'salesOrderNumber');
  };

  const handleSuggestionSelection = (event) => {
    const matchingOrder = ShopOrdersStore.paginatedShopOrders.results.find(
      (order) => order.salesOrderNumber === event
    );
    if (matchingOrder) {
      setSelectedOrder(matchingOrder);
      fetchJobsForOrder(matchingOrder);
    }
  }

  const fetchJobsForOrder = (order) => {
    JobStore.fetchPaginatedJobs(1, 20, '-enteredDate', { orderId: order._id }, '', 'salesOrderNumber')
      .then((response) => {
        const jobs = response.results.filter((job) => job.workType !== 'S');
        setOrderJobs(jobs);
      })
      .catch((error) => {
        setOrderJobs([]);
        setPieces(0);
        setHours(0);
        if (!error?.response?.data?.message === 'No documents found.') {
          setSnackbar({ open: true, message: 'Failed to fetch jobs for order', severity: 'error' });
        }
      });
  };

  const handleSaveProductionInfo = async () => {
    const newJob = {
      workType: 'PR',
      orderId: selectedOrder._id,
      jobDate: new Date(),
      hours: hours,
      pieces: pieces,
      machineId: selectedOrder.machine,
      orderNumber: selectedOrder.orderNumber,
    };

    try {
      await JobStore.createJob(newJob);
      fetchJobsForOrder(selectedOrder); // Fetch jobs again to add the new row
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to create job', severity: 'error' });
    }
  };

  const columns = [
    { field: 'jobId', headerName: 'Job Number', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'pieces', headerName: 'Pieces', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'hours', headerName: 'Hours', flex: 1, align: 'center', headerAlign: 'center' },
  ];

  const rows = orderJobs.map((job, index) => ({
    id: index,
    jobNumber: job.jobNumber,
    pieces: job.pieces,
    hours: job.hours,
  }));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', overflowX: 'hidden' }}>
      <Box sx={{ width: '100%', mb: 2 }}>
        <DynamicSearchFilter
          defaultSort="-enteredDate"
          onSearch={handleDynamicSearch}
          objectInterface={{ salesOrderNumber: '' }}
          label="Shop Order"
          suggestions={ShopOrdersStore.paginatedShopOrders.results.map((order) => order.salesOrderNumber)}
          onSuggestionSelected={handleSuggestionSelection}
        />
      </Box>

      {selectedOrder && (
        <Box sx={{ width: '100%', mb: 4 }}>
          <Typography variant="h6">Order Information</Typography>
          <Typography variant="body1">
            <strong>Order Number:</strong> {selectedOrder.salesOrderNumber}
          </Typography>
          <Typography variant="body1">
            <strong>Complete:</strong> {selectedOrder.complete ? 'Yes' : 'No'}
          </Typography>
        </Box>
      )}

      {selectedOrder && (
        <Box sx={{ mb: 4, width: '50%' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Production Info
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Pieces"
              type="number"
              value={pieces}
              onChange={(e) => setPieces(Number(e.target.value))}
              fullWidth
            />
            <TextField
              label="Hours"
              type="number"
              value={hours}
              onChange={(e) => setHours(Number(e.target.value))}
              fullWidth
            />
          </Box>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleSaveProductionInfo}
            disabled={loading}
          >
            Add Production
          </Button>
        </Box>
      )}

      {orderJobs.length > 0 && (
        <Box sx={{ width: '100%', mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Historical Production Data
          </Typography>
          <DataGridPro rows={rows} columns={columns} pageSize={5} autoHeight />
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ open: false, message: '', severity: 'info' })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ open: false, message: '', severity: 'info' })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Production;
