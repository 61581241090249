import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import CustomerStore from '../../stores/CustomerStore';
import CustomerForm from './CustomerForm';
import { Box, Typography, CircularProgress, Snackbar, Alert, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const EditCustomer = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  useEffect(() => {
    setLoading(true);
    const fetchCustomer = async () => {
      try {
        await CustomerStore.fetchCustomerById(customerId);
        setCustomer(CustomerStore.customer);
      } catch (error) {
        console.error('Failed to fetch customer:', error);
        setSnackbar({ open: true, message: 'Failed to fetch customer', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [customerId]);

  const handleSubmit = async (updatedCustomer) => {
    try {
      console.log(updatedCustomer);
      if (!updatedCustomer.companyName) throw new Error("Customer name is required");
      if (!updatedCustomer.companyEmail) throw new Error("Customer email is required");
      if (!updatedCustomer.companyPhone) throw new Error("Customer phone is required");

      await CustomerStore.updateCustomer(customerId, updatedCustomer);
      navigate('/customers');
    } catch (error) {
      console.error('Failed to update customer:', error);
      setSnackbar({ open: true, message: 'Failed to update customer', severity: 'error' });
    }
  };

  const handleDelete = async () => {
    try {
      await CustomerStore.deleteCustomer(customerId);
      navigate('/customers');
    } catch (error) {
      console.error('Failed to delete customer:', error);
      setSnackbar({ open: true, message: 'Failed to delete customer', severity: 'error' });
    }
  };

  const goToShippingAddresses = async () => {
    navigate(`/customer/${customerId}/shippingAddresses`);
  }

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Link to="/customers" style={{ textDecoration: 'none' }}>
            <ArrowBackIcon sx={{ color: 'primary.main', fontSize: 30, marginRight: '20px' }} />
          </Link>
          <Typography variant="h4">Edit Customer</Typography>
        </Box>
        <IconButton component={Link} to={`/customer/${customerId}`}>
          <CloseIcon />
        </IconButton>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        customer && <CustomerForm initialCustomerData={customer} onSubmit={handleSubmit} onDelete={handleDelete} shippingAddresses={goToShippingAddresses} />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditCustomer;
