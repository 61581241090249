import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class AccountCodeStore {
  accountCodes = [];
  paginatedAccountCodes = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  }
  accountCode = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedAccountCodes(
    page = 1,
    pageSize = 10,
    sortBy = 'accountCode',
    filter = {},
    search = '',
    searchField = 'accountCode'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/accountCodes?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      this.paginatedAccountCodes = { ...pagination, results: results };
      return { results, ...pagination };
    } catch (error) {
      console.error("Error fetching paginated account codes:", error);
    }
  }

  async fetchAccountCodeById(accountCodeId) {
    try {
      const response = await apiHandler.get(`/accountCodes/${accountCodeId}`);
      this.accountCode = await response;
      return response;
    } catch (error) {
      console.error("Error fetching account code by ID:", error);
    }
  }

  async createAccountCode(accountCodeData) {
    try {
      const newAccountCode = await apiHandler.post('/accountCodes', accountCodeData);
      this.accountCodes.push(newAccountCode);
    } catch (error) {
      console.error("Error creating account code:", error);
    }
  }

  async updateAccountCode(accountCodeId, accountCodeData) {
    try {
      const updatedAccountCode = await apiHandler.put(`/accountCodes/${accountCodeId}`, accountCodeData);
      const index = this.accountCodes.findIndex(accountCode => accountCode._id === accountCodeId);
      if (index > -1) {
        this.accountCodes[index] = updatedAccountCode;
      }
    } catch (error) {
      console.error("Error updating account code:", error);
    }
  }

  async deleteAccountCode(accountCodeId) {
    try {
      await apiHandler.delete(`/accountCodes/${accountCodeId}`);
      this.accountCodes = this.accountCodes.filter(accountCode => accountCode._id !== accountCodeId);
    } catch (error) {
      console.error("Error deleting account code:", error);
    }
  }

}

export default new AccountCodeStore();
