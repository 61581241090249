import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, IconButton, useTheme
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import PayablesAccountCodesPDF from '../../../pdf/PayablesAccountCodesPDF';
import AccountCodeSearch from '../../../components/search/AccountCodeSearch';
import { toJS } from 'mobx';
import DateRangePicker from '../../../components/DateRangePicker';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AccountCodesReport = observer(() => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [accountCode, setAccountCode] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const theme = useTheme();

  const fetchPayablesAccountCodes = async (params) => {
    setLoading(true);
    ReportsStore.fetchPayablesAccountCodes(params).then(() => {
      setReport(ReportsStore.detailedReports.payableAccountCodes.result);
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setSnackbar({ open: true, message: 'Failed to fetch payables account codes report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateReports = () => {
    if (!accountCode) {
      setSnackbar({ open: true, message: 'You must select an account code.' });
    } else {
      const params = {
        accountCode,
        startDate,
        endDate
      }
      fetchPayablesAccountCodes(params);
    }
  }

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'payablesId', headerName: 'ID', flex: 1 },
    { field: 'invoiceAmount', headerName: 'Invoice Amount', flex: 1 },
    { field: 'paidAmount', headerName: 'Paid Amount', flex: 1 },
    { field: 'datePaid', headerName: 'Date Paid', flex: 1, valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'dueDate', headerName: 'Due Date', flex: 1, valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'invoiceDate', headerName: 'Invoice Date', flex: 1, valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY') },
    { field: 'invoiceNo', headerName: 'Invoice No', flex: 1 },
    { field: 'vendor', headerName: 'Vendor', flex: 1, valueGetter: ({ row }) => row.vendor.vendorName },
    { field: 'void', headerName: 'Void', flex: 1, type: 'boolean' },
  ];

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Payables Account Codes Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12} md={6}>
              <AccountCodeSearch
                sx={{ width: '100%' }}
                handleChange={(event) => setAccountCode(event ? event._id : '')}
                marginTop={0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateRangePicker
                defaultValue='This Week'
                onChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button variant="outlined" onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }}>Get Payables</Button>
              {report && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <PayablesAccountCodesPDF report={report} />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (
          <DataGridPro
            rows={report || []}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.payablesId}
          />
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default AccountCodesReport;
