import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Paper, Tabs, Tab
} from '@mui/material';
import Products from './reports/ProductReports/Products';
import Employees from './reports/EmployeeReports/Employees';
import Sales from './reports/SalesReports/Sales';
import Payables from './reports/PayablesReports/Payables';

const Reports = observer(() => {
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeParam = searchParams.get('type');

    switch (typeParam) {
      case 'productions':
        setTab(0);
        break;
      case 'employees':
        setTab(1);
        break;
      case 'sales':
        setTab(2);
        break;
      case 'payables':
        setTab(3);
        break;
      default:
        setTab(0);
    }
  }, [location.search]); 

  const handleTabChange = (event, newValue) => {
    setTab(newValue);

    console.log(newValue);

    const tabParams = [
      'productions',
      'employees',
      'sales',
      'payables'
    ];

    navigate(`?type=${tabParams[newValue]}`);
  };

  const renderTabPanel = () => {
    switch (tab) {
      case 0:
        return <Products />;
      case 1:
        return <Employees />;
      case 2:
        return <Sales />;
      case 3:
        return <Payables />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Tabs value={tab} onChange={handleTabChange} aria-label="report tabs">
        <Tab label="Productions" />
        <Tab label="Employees" />
        <Tab label="Sales" />
        <Tab label="Payables" />
      </Tabs>

      <Paper sx={{ overflow: 'auto' }}>
        {renderTabPanel()}
      </Paper>

    </Box>
  );
});

export default Reports;