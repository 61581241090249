import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddressEdit from "../../components/AddressEdit";
import { toJS } from 'mobx';

const VendorForm = ({ initialVendorData, onSubmit, onDelete, back }) => {
  const [vendor, setVendor] = useState({
    vendorName: "",
    vendorOurAcct: "",
    vendorId: "",
    federalId: "",
    salesId: "",
    active: false,
    contact: "",
    email: "",
    phone: "",
    fax: "",
    website: "",
    acctCode: "",
    glAcct: "0",
    payments: 0,
    purchases: 0,
    discDays: 0,
    discPercent: 0,
    netDays: 0,
    comments: "",
    address: {
      name: "",
      street: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      country: ""
    },
    paymentAddress: {
      name: "",
      street: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      country: ""
    },
    taxInfo: {
      yn1099: false,
      box1099: "",
      type1099: ""
    },
    notes: [],
    attachments: [],
  });

  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });

  useEffect(() => {
    if (initialVendorData) {
      console.log(toJS(initialVendorData));
      setVendor({
        ...initialVendorData,
        address: {
          name: "",
          street: "",
          line2: "",
          city: "",
          state: "",
          postalCode: initialVendorData.address.zip || "",
          zip: "",
          country: "",
          ...initialVendorData.address
        },
        paymentAddress: {
          name: "",
          street: "",
          line2: "",
          city: "",
          state: "",
          postalCode: initialVendorData.paymentAddress.zip || "",
          zip: "",
          country: "",
          ...initialVendorData.paymentAddress
        }
      });
    }
  }, [initialVendorData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setVendor({ ...vendor, [name]: type === 'checkbox' ? checked : value });
  };

  const handleAddressChange = (type, updatedAddress) => {
    setVendor({
      ...vendor,
      [type]: updatedAddress,
    });
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!vendor.vendorName) {
      newErrors.vendorName = "Vendor Name is required";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(vendor);
    } else {
      setSnackbar({ open: true, message: "Please fill in all required fields", severity: "error" });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "" });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" mt={4} mb={2} gutterBottom>Vendor Information</Typography>
          <TextField
            fullWidth
            name="vendorName"
            label="Vendor Name"
            value={vendor.vendorName}
            onChange={handleInputChange}
            margin="normal"
            error={!!errors.vendorName}
            helperText={errors.vendorName}
          />
          <TextField
            fullWidth
            name="vendorOurAcct"
            label="Our Account#"
            value={vendor.vendorOurAcct}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="vendorId"
            label="Vendor ID"
            value={vendor.vendorId}
            onChange={handleInputChange}
            margin="normal"
            error={!!errors.vendorId}
            helperText={errors.vendorId}
          />
          <TextField
            fullWidth
            name="contact"
            label="Contact"
            value={vendor.contact}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="phone"
            label="Phone"
            value={vendor.phone}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="fax"
            label="Fax"
            value={vendor.fax}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="federalId"
            label="Federal ID"
            value={vendor.federalId}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="salesId"
            label="Sales ID"
            value={vendor.salesId}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="email"
            label="Email"
            value={vendor.email}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="website"
            label="Website"
            value={vendor.website}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="comments"
            label="Comments"
            value={vendor.comments}
            onChange={handleInputChange}
            margin="normal"
            multiline
            rows={4}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={vendor.active}
                onChange={handleInputChange}
                name="active"
              />
            }
            label="Active"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AddressEdit
            address={vendor.address}
            fieldNames={{name: "name", street1: "street", street2: "line2", city: "city", state: "state", zip: "postalCode", country: "country"}}
            onChange={(updatedAddress) => handleAddressChange("address", updatedAddress)}
          />
          <AddressEdit
            address={vendor.paymentAddress}
            label="Payment Address"
            fieldNames={{name: "name", street1: "street", street2: "line2", city: "city", state: "state", zip: "postalCode", country: "country"}}
            onChange={(updatedAddress) => handleAddressChange("paymentAddress", updatedAddress)}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          onClick={() => back()}
        >
          Back to Vendors
        </Button>

        <Box sx={{ display: "flex", gap: 2 }}>
          {onDelete && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onDelete(vendor._id)}
            >
              Delete
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {initialVendorData ? "Update" : "Add"}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VendorForm;
