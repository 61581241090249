import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Paper, Box, Button, Snackbar, Grid, Modal, IconButton, useTheme, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import MonthlySalesPDF from '../../../pdf/MonthlySalesPDF';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MonthlySalesReport = observer(() => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const theme = useTheme();

  const fetchMonthlySales = async (startDate, endDate) => {
    setLoading(true);
    ReportsStore.fetchMonthlySales(startDate, endDate).then(() => {
      setReport(ReportsStore.detailedReports.monthlySales);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch monthly sales report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateReports = () => {
    const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : '';
    fetchMonthlySales(formattedStartDate, formattedEndDate);
  }

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'salesOrderNumber', headerName: 'Order No.', flex: 0.5 },
    { field: 'customerName', headerName: 'Customer Name', flex: 1 },
    { field: 'customerPONumber', headerName: 'PO No.', flex: 0.6 },
    {
      field: 'enteredDate',
      headerName: 'Date Entered',
      flex: .7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy')
    },
    { field: 'status', headerName: 'Status', flex: .5 },
    { field: 'millJobNumber', headerName: 'Mill No.', flex: 0.5 },
    {
      field: 'honeInnerDiameter',
      headerName: 'Hone ID',
      flex: .75,
      valueFormatter: (params) => `${params.value.lowerLimit.toFixed(3)}/${params.value.upperLimit.toFixed(3)}`
    },
    { field: 'tubeOuterDiameter', headerName: 'Tube OD', flex: .5, valueFormatter: (params) => params.value.toFixed(3) },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: .7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy')
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: .7,
      valueFormatter: (params) => `$${params.value.toFixed(2)}`
    },
  ];

  const CustomFooter = () => {
    const total = report?.result.reduce((sum, row) => sum + row.total, 0) || 0;
    const formattedTotal = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(total);
    return (
      <Box sx={{ padding: 2, textAlign: 'right' }}>
        <Typography variant="h6">Grand Total: {formattedTotal}</Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Monthly Sales Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button variant="outlined" onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }}>Get Sales</Button>
              {report && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <MonthlySalesPDF report={report} startDate={startDate} endDate={endDate} />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (
          <DataGridPro
            rows={report?.result || []}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.orderNumber}
            slots={{
              footer: CustomFooter,
            }}
          />
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default MonthlySalesReport;
