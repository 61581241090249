import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import {
  Box, Typography, Snackbar, Alert, CircularProgress, IconButton,
  useMediaQuery, List, ListItem, ListItemText, ListItemSecondaryAction, TablePagination, Button, Toolbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import CustomerStore from '../../stores/CustomerStore';

const OrdersPage = observer(() => {
  const [orders, setOrders] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('-enteredDate');
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(false);
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));
  const navigate = useNavigate();

  useEffect(() => {
    fetchPaginatedOrders();
  }, [page, pageSize, sortBy, filter, search]);

  const fetchPaginatedOrders = async () => {
    setIsLoading(true);

    ShopOrdersStore.fetchPaginatedShopOrders(page + 1, pageSize, sortBy, filter, search)
      .then(() => {
        setOrders(ShopOrdersStore.paginatedShopOrders);
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Failed to fetch orders', severity: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const handleViewOrder = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  const handleEdit = () => {
    if (selectedRows.length === 1) {
      navigate(`/orders/edit/${selectedRows[0]}`);
    }
  };

  const handleInvoiceClick = () => {
    if (selectedRows.length === 1) {
      navigate(`/receivables/edit/new`, { state: { orderId: selectedRows[0] } });
    }
  }

  const handleDeleteOrder = () => {
    if (selectedRows.length > 0) {
      // Add your delete logic here
      const selectedOrderIds = selectedRows.join(', ');
      alert(`Delete orders: ${selectedOrderIds}`);
    }
  };

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection);
  };

  const handleDynamicSearch = async (searchQuery, filterCriteria, sortBy) => {
    setSearchTerm(searchQuery);
    if (sortBy) {
      setSortBy(sortBy);
    }

    let filterBuilder = {};

    let keys = Object.keys(filterCriteria);
    if (keys.length > 0) {
      if (filterCriteria.dueDateFrom || filterCriteria.dueDateTo) {
        filterBuilder.dueDate = {};
        if (filterCriteria.dueDateFrom) filterBuilder.dueDate.$gte = new Date(filterCriteria.dueDateFrom).toISOString();
        if (filterCriteria.dueDateTo) filterBuilder.dueDate.$lte = new Date(filterCriteria.dueDateTo).toISOString();
      }
      if (filterCriteria.enteredDateFrom || filterCriteria.enteredDateTo) {
        filterBuilder.enteredDate = {};
        if (filterCriteria.enteredDateFrom) filterBuilder.enteredDate.$gte = new Date(filterCriteria.enteredDateFrom).toISOString();
        if (filterCriteria.enteredDateTo) filterBuilder.enteredDate.$lte = new Date(filterCriteria.enteredDateTo).toISOString();
      }
      if (filterCriteria.shipDateFrom || filterCriteria.shipDateTo) {
        filterBuilder.shipDate = {};
        if (filterCriteria.shipDateFrom) filterBuilder.shipDate.$gte = new Date(filterCriteria.shipDateFrom).toISOString();
        if (filterCriteria.shipDateTo) filterBuilder.shipDate.$lte = new Date(filterCriteria.shipDateTo).toISOString();
      }
      if (filterCriteria.total?.min || filterCriteria.total?.max) {
        filterBuilder.total = {};
        if (filterCriteria.total.min) filterBuilder.total.$gte = filterCriteria.total.min;
        if (filterCriteria.total.max) filterBuilder.total.$lte = filterCriteria.total.max;
      }
    }

    if (searchQuery) {
      const customerSearchFilter = {
        $or: [
          { companyName: { $regex: searchQuery, $options: 'i' } },
          { companyAbbreviation: { $regex: searchQuery, $options: 'i' } },
          { companyId: { $regex: searchQuery, $options: 'i' } }
        ]
      };

      try {
        const { results: customers } = await CustomerStore.fetchPaginatedCustomers(1, 30, 'companyName', customerSearchFilter, '', 'companyName');

        if (customers && customers.length > 0) {
          const customerIds = customers.map(customer => customer._id);
          filterBuilder.customer = { $in: customerIds };
        } else {
          const searchFilter = {
            $or: [
              { shopOrderNumber: { $regex: searchQuery, $options: 'i' } },
              { salesOrderNumber: { $regex: searchQuery, $options: 'i' } },
              { millJobNumber: { $regex: searchQuery, $options: 'i' } },
              { customerPONumber: { $regex: searchQuery, $options: 'i' } },
            ]
          }
          filterBuilder = { ...filterBuilder, ...searchFilter };
        }

      } catch (error) {
        const searchFilter = {
          $or: [
            { shopOrderNumber: { $regex: searchQuery, $options: 'i' } },
            { salesOrderNumber: { $regex: searchQuery, $options: 'i' } },
            { millJobNumber: { $regex: searchQuery, $options: 'i' } },
            { customerPONumber: { $regex: searchQuery, $options: 'i' } },
          ]
        }

        filterBuilder = { ...filterBuilder, ...searchFilter };
      }
    }
    console.log(filterBuilder);
    setPage(0);
    setFilter(filterBuilder);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button onClick={handleEdit} startIcon={<EditIcon />} disabled={selectedRows.length !== 1}>
        Edit
      </Button>
      <Button onClick={handleInvoiceClick} startIcon={<PaymentsIcon />} disabled={selectedRows.length !== 1}>
        Invoice
      </Button>
      {/* <Button onClick={handleDeleteOrder} startIcon={<DeleteIcon />} disabled={selectedRows.length === 0}>
        Delete
      </Button> */}
    </GridToolbarContainer>
  );

  const highlightMatch = (searchTerm, fieldValue) => {
    if (!searchTerm) return fieldValue;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = fieldValue.split(regex);
    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <b key={index} style={{ fontWeight: 'bold' }}>
              {part}
            </b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const shouldHighlightRow = (searchTerm, row) => {
    if (!searchTerm || searchTerm.length < 3) return false;

    const lowerSearchTerm = searchTerm.toLowerCase();

    return (
      row.salesOrderNumber.toLowerCase().includes(lowerSearchTerm) ||
      row.customerPONumber.toLowerCase().includes(lowerSearchTerm) ||
      row.millJobNumber.toLowerCase().includes(lowerSearchTerm)
    );
  };


  const columns = (searchTerm) => [
    {
      field: 'salesOrderNumber',
      headerName: 'Order No.',
      flex: 0.5,
      editable: false,
      sortable: false,
      renderCell: (params) => highlightMatch(searchTerm, params.value),
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'customerPONumber',
      headerName: 'PO No.',
      flex: 0.6,
      editable: false,
      sortable: false,
      renderCell: (params) => highlightMatch(searchTerm, params.value),
    },
    {
      field: 'enteredDate',
      headerName: 'Date Entered',
      flex: 0.7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy'),
      editable: false,
      sortable: false,
    },
    {
      field: 'millJobNumber',
      headerName: 'Mill No.',
      flex: 0.5,
      editable: false,
      sortable: false,
      renderCell: (params) => highlightMatch(searchTerm, params.value),
    },
    {
      field: 'honeInnerDiameter',
      headerName: 'Hone ID',
      flex: 0.75,
      valueFormatter: (params) => `${params.value.lowerLimit.toFixed(3)}/${params.value.upperLimit.toFixed(3)}`,
      editable: false,
      sortable: false,
    },
    {
      field: 'tubeOuterDiameter',
      headerName: 'Tube OD',
      flex: 0.5,
      valueFormatter: (params) => params.value.toFixed(3),
      editable: false,
      sortable: false,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 0.7,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy'),
      editable: false,
      sortable: false,
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 0.7,
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
      editable: false,
      sortable: false,
    }
  ];

  const orderInterface = {
    orderNumber: '',
    customerPONumber: '',
    millJobNumber: '',
    customer: '',
    status: '',
    paymentStatus: '',
    dueDate: new Date(),
    enteredDate: new Date(),
    shipDate: new Date(),
    total: { min: 0, max: 0 },
    honeIDLowerLimit: {
      min: 0,
      max: 0,
    },
    honeIDUpperLimit: {
      min: 0,
      max: 0
    },
    tubeOuterDiameter: {
      min: 0,
      max: 0
    },
    isCancelled: false,
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <DynamicSearchFilter
          defaultSort="-enteredDate"
          objectInterface={orderInterface}
          onSearch={handleDynamicSearch}
        />
        <IconButton color="primary" onClick={() => navigate('/orders/add')}>
          <AddIcon />
        </IconButton>
      </Box>

      <Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {isLargeScreen ? (
              <DataGridPro
                rows={orders.results}
                columns={columns(searchTerm)}
                loading={isLoading}
                autoHeight
                checkboxSelection
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSort
                onRowClick={(params) => handleViewOrder(params.id)}
                onRowSelectionModelChange={(selection) => {
                  console.log(selection);
                  setSelectedRows(selection);
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
                editMode="row"
                selectionModel={selectedRows}
                getRowClassName={(params) =>
                  shouldHighlightRow(searchTerm, params.row) ? 'highlight-row' : ''
                }
                sx={{
                  '& .highlight-row': {
                    backgroundColor: '#efefef',
                  },
                }}
              />

            ) : (
              <List>
                {orders.results.map((order) => (
                  <ListItem
                    key={order._id}
                    button
                    onClick={() => handleViewOrder(order._id)}
                  >
                    <ListItemText
                      primary={`Order #${order.salesOrderNumber}`}
                      secondary={`Customer: ${order.customerName}, Date: ${format(new Date(order.enteredDate), 'MM/dd/yyyy')}, Due: ${format(new Date(order.dueDate), 'MM/dd/yyyy')}, Total: $${order.total}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <TablePagination
              component="div"
              count={orders.totalCount}
              page={page}
              onPageChange={(e, newPage) => handlePageChange(newPage)}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(e) => handleRowsPerPageChange(parseInt(e.target.value, 10))}
              rowsPerPageOptions={[10, 25, 50]}
              sx={{ mt: 1 }}
            />
          </>
        )}
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default OrdersPage;
