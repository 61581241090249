import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PayablesStore from '../../stores/PayablesStore';
import PayableForm from './PayableForm';
import {
  Box, Typography, CircularProgress, Snackbar, Alert, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const EditPayable = () => {
  const { payableId } = useParams();
  const navigate = useNavigate();
  const [payable, setPayable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchPayable = async () => {
      try {
        await PayablesStore.fetchPayableById(payableId);
        setPayable(PayablesStore.payable);
      } catch (error) {
        setError('Failed to fetch payable');
      } finally {
        setLoading(false);
      }
    };

    fetchPayable();
  }, [payableId]);

  const handleSubmit = async (updatedPayable) => {
    try {
      setLoading(true);
      await PayablesStore.updatePayable(payableId, updatedPayable);
      navigate('/payables');
    } catch (error) {
      setError('Failed to update payable');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await PayablesStore.deletePayable(payableId);
      navigate('/payables');
    } catch (error) {
      setError('Failed to delete payable');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">
            Edit Payable
          </Typography>
        </Box>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => setDeleteDialogOpen(true)}>
            <DeleteIcon sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        payable ? (
          <PayableForm initialPayableData={payable} onSubmit={handleSubmit} />
        ) : (
          <Typography variant="body1">Payable not found</Typography>
        )
      )}

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Payable</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this payable?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={error !== null} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditPayable;
