import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, Grid, Modal, IconButton, useTheme, TextField, List, ListItem, ListItemText
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import EmployeeScrapPDF from '../../../pdf/EmployeeScrapPDF';
import { toJS } from 'mobx';
import EmployeeSearch from '../../../components/search/EmployeeSearch';
import { DataGridPro } from '@mui/x-data-grid-pro';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ListDetailPanelContent({ row: rowProp }) {

  const columns = [
    { field: 'salesOrderNumber', headerName: 'Order #', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1, valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY') },
    { field: 'customer', headerName: 'Customer', flex: 1 },
    { field: 'machine', headerName: 'Machine', flex: 1 },
    { field: 'feet', headerName: 'Feet', flex: 1 },
    { field: 'outerDiameter', headerName: 'OD', flex: 1 },
    { field: 'pieces', headerName: 'Pieces', flex: 1 },
    { field: 'comments', headerName: 'Comments', flex: 2 }
  ]

  return (
    <Paper>
      <DataGridPro
        density='compact'
        rows={rowProp.scrapEntries}
        columns={columns}
        sx={{ flex: 1, p: 1 }}
        hideFooter
        getRowId={(row) => `${row.salesOrderNumber}-${row.date}`}
      />
    </Paper>
  );
};

const EmployeeScrapReport = observer(() => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [workDateFrom, setWorkDateFrom] = useState(null);
  const [workDateTo, setWorkDateTo] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    const today = new Date();
    const sunday = new Date(today);
    sunday.setDate(today.getDate() - today.getDay());
    const saturday = new Date(sunday);
    saturday.setDate(sunday.getDate() + 6);
    setWorkDateFrom(dayjs(sunday));
    setWorkDateTo(dayjs(saturday));
  }, [])

  const fetchEmployeeScrap = async (params) => {
    setLoading(true);
    ReportsStore.fetchEmployeeScrapReport(params).then(() => {
      const scrapReport = toJS(ReportsStore.detailedReports.employeeScrap.result);
      setReport(scrapReport);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch employee scrap report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateReports = () => {
    const workStart = workDateFrom ? dayjs(workDateFrom).format('YYYY-MM-DD') : '';
    const workEnd = workDateTo ? dayjs(workDateTo).format('YYYY-MM-DD') : '';

    const scrapReportParams = {
      employeeId,
      fromDate: workStart,
      toDate: workEnd
    }

    fetchEmployeeScrap(scrapReportParams);
  }

  const handleClose = () => {
    setViewPdf(false);
  }

  const columns = [
    { field: 'name', headerName: 'Employee Name', flex: 3, headerAlign: 'center' },
    { field: 'totalPieces', headerName: 'Total Pieces', flex: 1, headerAlign: 'center' },
    { field: 'totalFeet', headerName: 'Total Feet', flex: 1, headerAlign: 'center', valueFormatter: ({ value }) => value.toFixed(2) || 0 },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <ListDetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(
    ({ row }) => {
      return (row.scrapEntries.length * 40) + 50;
    },
    []
  );

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Employee Scrap Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            <Grid item xs={12} md={6}>
              <EmployeeSearch
                sx={{ width: '100%' }}
                handleChange={(event) => setEmployeeId(event ? event._id : '')}
                showAdd={false}
                marginTop={0}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Work Days From"
                  value={workDateFrom}
                  onChange={(newValue) => setWorkDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="Work Days To"
                  value={workDateTo}
                  onChange={(newValue) => setWorkDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button variant="outlined" onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }} disabled={loading}>Get Scrap</Button>
              {report && (
                <Button onClick={() => setViewPdf(true)} sx={{ width: '200px' }} disabled={loading}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        <DataGridPro
          rows={report || []}
          columns={columns}
          autoHeight
          loading={loading}
          getRowId={(row) => row.employeeId}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
        />
        {viewPdf && (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <EmployeeScrapPDF report={report} startDate={workDateFrom} endDate={workDateTo} />
              </PDFViewer>
            </Paper>
          </Modal>
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default EmployeeScrapReport;
