import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, Box, Avatar, Menu, MenuItem, useMediaQuery, useTheme, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined';
import PaymentIcon from '@mui/icons-material/PaymentOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import ReportIcon from '@mui/icons-material/ReportOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import authStore from '../stores/AuthStore';
import { AccountBalance } from '@mui/icons-material';

const generateAvatarBg = () => {
  const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33F6', '#F6FF33'];
  return colors[Math.floor(Math.random() * colors.length)];
};

export default function Navbar({ children, username }) {
  const navigate = useNavigate();
  const location = useLocation(); // This will give us the current route
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [avatarBg, setAvatarBg] = useState(localStorage.getItem('avatarBg') || generateAvatarBg());
  const [openGroups, setOpenGroups] = useState({});

  const drawerWidth = 275;

  useEffect(() => {
    if (!localStorage.getItem('avatarBg')) {
      localStorage.setItem('avatarBg', avatarBg);
    }
    if (!isLargeScreen) {
      setOpenGroups({
        'Production Center': true,
        Finance: true,
        People: true,
        System: true,
      });
    }
  }, [avatarBg, isLargeScreen]);

  // Get the current page title from the location
  const getCurrentPageTitle = () => {
    const pathToTitleMap = {
      '/dashboard': 'Overview',
      '/orders': 'Shop Orders',
      '/productions': 'Production',
      '/reports': 'Reports',
      '/receivables': 'Accounts Receivable',
      '/customers': 'Customers',
      '/account-codes': 'Account Codes',
      '/payables': 'Accounts Payable',
      '/vendors': 'Vendors',
      '/payroll': 'Payroll',
      '/employees': 'Employees',
      '/users': 'User Accounts',
      '/preferences': 'System Preferences',
      '/licensing': 'Licensing',
      '/privacy': 'Privacy',
    };
    return pathToTitleMap[location.pathname] || '';
  };

  useEffect(() => {
    const pageTitle = getCurrentPageTitle();
    document.title = `Buckeye Hone - ${pageTitle}`;
  }, [location.pathname]);

  const handleToggle = (group) => {
    if (isLargeScreen) {
      setOpenGroups((prevOpenGroups) => ({
        ...prevOpenGroups,
        [group]: !prevOpenGroups[group],
      }));
    }
  };

  const drawerItems = [
    {
      group: 'Production Center',
      items: [
        { name: 'Overview', icon: <DashboardIcon />, to: '/dashboard' },
        { name: 'Shop Orders', icon: <ReceiptIcon />, to: '/orders' },
        { name: 'Production', icon: <ProductionQuantityLimitsIcon />, to: '/productions' },
        { name: 'Reports', icon: <ReportIcon />, to: '/reports' },
      ],
    },
    {
      group: 'Finance',
      items: [
        { name: 'Accounts Receivable', icon: <PaymentsIcon />, to: '/receivables' },
        { name: 'Customers', icon: <PeopleIcon />, to: '/customers' },
        { name: 'Accounts Payable', icon: <PaymentIcon />, to: '/payables' },
        { name: 'Vendors', icon: <BusinessIcon />, to: '/vendors' },
        { name: 'Account Codes', icon: <AccountBalance />, to: '/account-codes'},
      ],
    },
    {
      group: 'People',
      items: [
        { name: 'Employees', icon: <PeopleIcon />, to: '/employees' },
        { name: 'Payroll', icon: <PaymentsIcon />, to: '/payroll' },
        { name: 'User Accounts', icon: <GroupIcon />, to: '/users' },
      ],
    },
    {
      group: 'System',
      items: [
        { name: 'System Preferences', icon: <SettingsIcon />, to: '/preferences' },
        { name: 'Licensing', icon: <DescriptionIcon />, to: '/licensing' },
        { name: 'Privacy', icon: <LockIcon />, to: '/privacy' },
      ],
    },
  ];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    authStore.logout();
    navigate('/login');
  };

  const initials = username ? username.charAt(0).toUpperCase() : 'U';

  const list = () => (
    <Box
      role="presentation"
      onClick={() => !isLargeScreen && setMobileOpen(false)}
      onKeyDown={() => !isLargeScreen && setMobileOpen(false)}
      sx={{ width: drawerWidth, display: 'flex', flexDirection: 'column', height: '100%', position: 'relative', mt: '56px', overflowX: 'hidden' }}
    >
      <List>
        {drawerItems.map(({ group, items }) => (
          <React.Fragment key={group}>
            <ListItem button onClick={() => handleToggle(group)} disabled={!isLargeScreen}>
              <ListItemText primary={group} />
              {isLargeScreen && (openGroups[group] ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            <Collapse in={isLargeScreen ? openGroups[group] : true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {items.map((item) => (
                  <ListItem
                    button
                    key={item.name}
                    component={Link}
                    to={item.to}
                    sx={{
                      pl: 4,
                      backgroundColor: location.pathname === item.to ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>

      <Divider />

      {/* Bottom Sections */}
      <ListItem button component={Link} to="/help">
        <ListItemIcon>
          <HelpOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="Help Center" />
      </ListItem>

      <Divider />

      <ListItem sx={{ position: 'relative', mt: 'auto' }}>
        <ListItemText style={{ opacity: 0.5 }} primary="© Kratesoft LLC" />
      </ListItem>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 'none',
          borderBottom: 'none',
          backgroundColor: 'white',
          color: 'black',
          borderRadius: 0,
          borderBottom: '2px solid',
          borderColor: 'primary.main',
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {!isLargeScreen && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMobileOpen(true)}>
              <MenuIcon />
            </IconButton>
          )}
         <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: 'flex'
            }}
          >
            <Box sx={{display: { xs: 'none', md: 'block' }}}>
             Buckeye Hone
              {getCurrentPageTitle().trim() && (<>&nbsp;-&nbsp;</>
              )}
            </Box>
            {getCurrentPageTitle()}
          </Typography>
          <IconButton size="large" edge="end" aria-label="account of current user" aria-haspopup="true" onClick={handleMenu} color="inherit">
            <Avatar sx={{ bgcolor: avatarBg }}>{initials}</Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openUserMenu}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/preferences" onClick={handleClose}>
              Preferences
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isLargeScreen ? 'permanent' : 'temporary'}
        anchor="left"
        open={isLargeScreen || mobileOpen}
        onClose={() => setMobileOpen(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', borderRight: '2px solid', borderColor: 'primary.main' },
        }}
      >
        {list()}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, paddingTop: '64px' }}>
        {children}
      </Box>
    </Box>
  );
}
