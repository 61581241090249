import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Select, MenuItem, Typography, FormControl
} from '@mui/material';
import EmployeeTimesheetReport from './EmployeeTimesheetReport';
import PayrollReport from './PayrollReport';
import AttendanceReport from './AttendanceReport';
import EmployeeScrapReport from './EmployeeScrapReport';

const Employees = () => {
  const [report, setReport] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const reportParam = searchParams.get('report');

    switch (reportParam) {
      case 'timesheet':
        setReport(0);
        break;
      case 'payroll':
        setReport(1);
        break;
      case 'attendance':
        setReport(2);
        break;
      case 'scrap':
        setReport(3);
        break;
      default:
        setReport(0);
    }
  }, [location.search]);


  const handleReportChange = (event) => {
    const selectedReport = event.target.value;
    setReport(selectedReport);

    const reportNames = [
      'timesheet',
      'payroll',
      'attendance',
      'scrap'
    ];

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('report', reportNames[selectedReport]);

    navigate(`?${searchParams.toString()}`);
  };

  const renderReport = () => {
    switch (report) {
      case 0:
        return <EmployeeTimesheetReport />;
      case 1:
        return <PayrollReport />;
      case 2:
        return <AttendanceReport />;
      case 3:
        return <EmployeeScrapReport />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <Typography variant='caption' sx={{ ml: 2, my: 1 }}>Select Report</Typography>
          <Select
            labelId="employee-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Timesheets</MenuItem>
            <MenuItem value={1}>Payroll</MenuItem>
            <MenuItem value={2}>Attendance</MenuItem>
            <MenuItem value={3}>Scrap</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Employees;