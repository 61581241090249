import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Select, MenuItem, FormControl, Typography
} from '@mui/material';
import MonthlySalesReport from './MonthlySalesReport';
import OrdersByMonthReport from './OrdersByMonthReport';
import SalesByMachineReport from './SalesByMachineReport';
import OrdersWrittenReport from './OrdersWrittenReport';
import SalesByCustomerReport from './SalesByCustomerReport';
import ReceivablesReport from './ReceivablesReport';

const Sales = () => {
  const [report, setReport] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const reportParam = searchParams.get('report');

    switch (reportParam) {
      case 'salesByCustomer':
        setReport(0);
        break;
      case 'monthlySales':
        setReport(1);
        break;
      case 'ordersByMonth':
        setReport(2);
        break;
      case 'salesByMachine':
        setReport(3);
        break;
      case 'ordersWritten':
        setReport(4);
        break;
      case 'receivables':
        setReport(5);
        break;
      default:
        setReport(0);
    }
  }, [location.search]);

  const handleReportChange = (event) => {
    const selectedReport = event.target.value;
    setReport(selectedReport);

    const reportNames = [
      'salesByCustomer',
      'monthlySales',
      'ordersByMonth',
      'salesByMachine',
      'ordersWritten',
      'receivables'
    ];

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('report', reportNames[selectedReport]);

    navigate(`?${searchParams.toString()}`);
  };

  const renderReport = () => {
    if (report === '') {
      return (
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Typography variant="h6" color="textSecondary">
            Please select a report to display.
          </Typography>
        </Box>
      );
    }

    switch (report) {
      case 0:
        return <SalesByCustomerReport />;
      case 1:
        return <MonthlySalesReport />;
      case 2:
        return <OrdersByMonthReport />;
      case 3:
        return <SalesByMachineReport />;
      case 4:
        return <OrdersWrittenReport />;
      case 5:
        return <ReceivablesReport />;
      default:
        return (
          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <Typography variant="h6" color="textSecondary">
              No valid report selected.
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <Typography variant='caption' sx={{ ml: 2, my: 1 }}>Select Report</Typography>
          <Select
            labelId="sales-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Sales By Customer</MenuItem>
            <MenuItem value={1}>Monthly Sales</MenuItem>
            <MenuItem value={2}>Orders By Month</MenuItem>
            <MenuItem value={3}>Sales By Machine</MenuItem>
            <MenuItem value={4}>Orders Written</MenuItem>
            <MenuItem value={5}>Receivables</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Sales;