import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Paper, Box, Button, Snackbar, Modal, IconButton, useTheme } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import SalesReceivablesPDF from '../../../pdf/SalesReceivablesPDF';
import DateRangePicker from '../../../components/DateRangePicker';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReceivablesReport = observer(() => {
  const [receivablesReport, setReceivablesReport] = useState({
    results: {
      receivables: []
    },
    message: '',
    status: ''
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewReceivablesPDF, setViewReceivablesPdf] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const theme = useTheme();

  const fetchReceivablesReport = async (params) => {
    setLoading(true);
    ReportsStore.fetchSalesOutstandingReceivablesReport(params).then(() => {
      setReceivablesReport(ReportsStore.detailedReports.outstandingReceivables);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch sales receivables report', severity: 'error' });
      setLoading(false);
    });
  }

  const generateReceivablesReport = () => {
    const params = {
      startDate,
      endDate
    }
    fetchReceivablesReport(params);
  }

  const handleClose = () => {
    setViewReceivablesPdf(false);
  }

  const receivablesColumns = [
    { field: 'amtPaid', headerName: 'Amount Paid', flex: 1, renderCell: (params) => params.row?.amountPaid?.$numberDecimal },
    { field: 'checkAmount', headerName: 'Check Amount', flex: 1, renderCell: (params) => params.row?.checkAmount?.$numberDecimal },
    { field: 'checkNumber', headerName: 'Check Number', flex: 1 },
    { field: 'invoiceNumber', headerName: 'Invoice Number', flex: 1 },
    { field: 'invoiceDate', headerName: 'Invoice Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString()}
  ]

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Receivables Report</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 2, gap: 3 }}>
          <DateRangePicker
            defaultValue='This Month'
            disableAllDates={true}
            onChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
          <Button variant="outlined" onClick={() => generateReceivablesReport()} sx={{ width: '200px', fontWeight: 'bold' }}>Get Receivables</Button>
          {receivablesReport.results?.receivables.length > 0 && (
            <Button onClick={() => setViewReceivablesPdf(true)} sx={{ width: '200px' }}>View as PDF</Button>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
          {viewReceivablesPDF ? (
            <Modal
              open={viewReceivablesPDF}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={handleClose}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width={'100%'} height={'100%'}>
                  <SalesReceivablesPDF report={receivablesReport.results} />
                </PDFViewer>
              </Paper>
            </Modal>
          ) : (
            <DataGridPro
              rows={receivablesReport?.results?.receivables || []}
              columns={receivablesColumns}
              autoHeight
              loading={loading}
              getRowId={(row) => row._id}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default ReceivablesReport;
