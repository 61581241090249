import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AccountCodeStore from '../../stores/AccountCodeStore';
import AccountCodeForm from './AccountCodeForm';
import { Box, Typography, CircularProgress, Button, Snackbar, Alert, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const EditAccountCode = () => {
  const { accountCodeId } = useParams();
  const navigate = useNavigate();
  const [accountCode, setAccountCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  useEffect(() => {
    setLoading(true);
    AccountCodeStore.fetchAccountCodeById(accountCodeId).then(() => {
      setAccountCode(AccountCodeStore.accountCode);
      setLoading(false);
    }).catch((error) => {
      setSnackbar({ open: true, message: 'Failed to fetch account code', severity: 'error' });
      setLoading(false);
    });
  }, [accountCodeId]);

  const handleSubmit = async () => {
    try {
      await AccountCodeStore.updateAccountCode(accountCodeId, accountCode);
      navigate('/account-codes');
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to update account code', severity: 'error' });
    }
  };

  const handleChange = (updatedAccountCode) => {
    setAccountCode(updatedAccountCode);
  };

  const handleBack = () => {
    navigate('/account-codes');
  };

  const handleView = () => {
    navigate(`/account-codes/${accountCodeId}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ ml: 2 }}>Edit Account Code</Typography>
        </Box>

        <IconButton onClick={handleView}>
          <CloseIcon />
        </IconButton>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        accountCode && (
          <AccountCodeForm accountCode={accountCode} onSubmit={handleSubmit} back={handleBack} onChange={handleChange} />
        )
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditAccountCode;
