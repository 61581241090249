import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const FrequencyPicker = ({ onChange, disabled }) => {
  const [selectedFrequency, setSelectedFrequency] = useState('monthly');

  const handleFrequencyChange = (frequency) => {
    setSelectedFrequency(frequency);
    if (onChange) {
      onChange(frequency);
    }
  };

  return (
    <Stack direction="row" spacing={1} margin={2}>
      <Chip
        label="Daily"
        clickable
        color={selectedFrequency === 'daily' ? 'primary' : 'default'}
        onClick={() => handleFrequencyChange('daily')}
        disabled={disabled}
      />
      <Chip
        label="Weekly"
        clickable
        color={selectedFrequency === 'weekly' ? 'primary' : 'default'}
        onClick={() => handleFrequencyChange('weekly')}
        disabled={disabled}
      />
      <Chip
        label="Monthly"
        clickable
        color={selectedFrequency === 'monthly' ? 'primary' : 'default'}
        onClick={() => handleFrequencyChange('monthly')}
        disabled={disabled}
      />
      <Chip
        label="Quarterly"
        clickable
        color={selectedFrequency === 'quarterly' ? 'primary' : 'default'}
        onClick={() => handleFrequencyChange('quarterly')}
        disabled={disabled}
      />
      <Chip
        label="Yearly"
        clickable
        color={selectedFrequency === 'yearly' ? 'primary' : 'default'}
        onClick={() => handleFrequencyChange('yearly')}
        disabled={disabled}
      />
      <Chip
        label="Custom"
        clickable
        color={selectedFrequency === 'custom' ? 'primary' : 'default'}
        onClick={() => handleFrequencyChange('custom')}
        disabled={disabled}
      />
    </Stack>
  );
};

export default FrequencyPicker;