import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class VendorStore {
  vendors = [];
  vendor = null;
  paginatedVendors = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchVendors() {
    try {
      const { results } = await apiHandler.get('/vendors');
      this.vendors = results;
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  }

  async fetchPaginatedVendors(
    page = 1,
    pageSize = 10,
    sortBy = 'vendorName',
    filter = {},
    search = '',
    searchField = 'vendorName'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/vendors?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      runInAction(() => {
        this.paginatedVendors = { ...pagination, results: results };
      })
      return this.paginatedVendors;
    } catch (error) {
      if (/No documents found/.test(error.response?.data?.message)) {
        runInAction(() => {
          this.paginatedVendors = {
            hasMore: false,
            totalCount: 0,
            currentPage: 0,
            pageSize: 10,
            totalPages: 0,
            results: []
          };
        });
      } else {
        console.error("Error fetching paginated vendors:", error);
        throw error
      }
    }
  }

  async fetchVendorById(vendorId) {
    try {
      const response = await apiHandler.get(`/vendors/${vendorId}`);
      this.vendor = response;
      return response;
    } catch (error) {
      console.error("Error fetching vendor by ID:", error);
      throw error;
    }
  }

  async createVendor(vendorData) {
    try {
      const response = await apiHandler.post('/vendors', vendorData);
      this.vendors.push(response);
    } catch (error) {
      console.error("Error creating vendor:", error);
      throw error;
    }
  }

  async updateVendor(vendorId, vendorData) {
    try {
      const response = await apiHandler.put(`/vendors/${vendorId}`, vendorData);
      this.vendor = response;
      const index = this.vendors.findIndex(vendor => vendor._id === vendorId);
      if (index > -1) {
        this.vendors[index] = response;
      }
    } catch (error) {
      console.error("Error updating vendor:", error);
      throw error;
    }
  }

  async deleteVendor(vendorId) {
    try {
      await apiHandler.delete(`/vendors/${vendorId}`);
      this.vendors = this.vendors.filter(vendor => vendor._id !== vendorId);
    } catch (error) {
      console.error("Error deleting vendor:", error);
      throw error;
    }
  }

}

export default new VendorStore();
