import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Paper, Divider, Grid, Button,
  Snackbar, Alert, IconButton, Stack, Avatar, Container, Card, CardContent
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmployeeStore from '../../stores/EmployeeStore';
import { format } from 'date-fns';

const formatAddress = (address) => {
  const { street, line2, city, state, postalCode } = address || {};
  return [street, line2, city, state, postalCode].filter(Boolean).join(', ');
};

const maskValue = (value, maskChar = '*') => {
  if (!value) return 'N/A';
  return value.replace(/.(?=.{4})/g, maskChar);
};

// Utility function to clean up Avatar name
const getInitials = (name = '') => {
  const cleaned = name.replace(/[^a-zA-Z]/g, ''); // Remove non-alphabetical characters
  return cleaned.charAt(0).toUpperCase() || 'N'; // Default to 'N' if empty
};

const EmployeeDetails = () => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSensitiveInfo, setShowSensitiveInfo] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const fetchedEmployee = await EmployeeStore.fetchEmployeeById(employeeId);
        setEmployee(fetchedEmployee);
      } catch (error) {
        console.error('Error fetching Employee:', error);
        setSnackbar({ open: true, message: 'Failed to load employee details', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchEmployee();
  }, [employeeId]);

  const toggleSensitiveInfo = () => {
    setShowSensitiveInfo(!showSensitiveInfo);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!employee) {
    return (
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <Typography variant="h5">Employee not found</Typography>
        <Link to="/employees" style={{ textDecoration: 'none' }}>
          <Typography variant="body1" color="primary">
            Go back to Employee List
          </Typography>
        </Link>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton component={Link} to="/employees">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">Employee Details</Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/employee/edit/${employeeId}`)}
          >
            Edit Employee
          </Button>
        </Box>

        <Grid container spacing={4}>
          {/* Personal Information */}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar sx={{ width: 56, height: 56 }}>
                  {getInitials(employee.firstName)}{getInitials(employee.lastName)}
                </Avatar>
                <Box>
                  <Typography variant="h6">{employee.firstName || 'N/A'} {employee.lastName || 'N/A'}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {employee.email || 'No email available'}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                      {showSensitiveInfo ? employee.phoneNumber || 'N/A' : maskValue(employee.phoneNumber)}
                    </Typography>
                    <IconButton onClick={toggleSensitiveInfo} size="small" sx={{ ml: 1 }}>
                      {showSensitiveInfo ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </Box>
                </Box>
              </Stack>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="textSecondary">Employee ID: {employee.employeeId || 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Name ID: {employee.nameID || 'N/A'}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="textSecondary">
                  {showSensitiveInfo ? employee.socialSecurityNumber || 'N/A' : maskValue(employee.socialSecurityNumber)}
                </Typography>
                <IconButton onClick={toggleSensitiveInfo} size="small" sx={{ ml: 1 }}>
                  {showSensitiveInfo ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </Box>
              <Typography variant="body2" color="textSecondary">Birth Date: {employee.birthDate ? format(new Date(employee.birthDate), 'MM/dd/yyyy') : 'N/A'}</Typography>
            </CardContent>
          </Grid>

          {/* Employment Information */}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Employment Details</Typography>
              <Typography variant="body2" color="textSecondary">Hire Date: {employee.hireDate ? format(new Date(employee.hireDate), 'MM/dd/yyyy') : 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Employment Type: {employee.employmentType || 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Is Active: {employee.isActive ? 'Yes' : 'No'}</Typography>
            </CardContent>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        <Grid container spacing={4}>
          {/* Address and Contact */}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Address</Typography>
              <Typography variant="body2" color="textSecondary">{formatAddress(employee.address) || 'N/A'}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="textSecondary">Emergency Contact: {employee.emergencyContactName || 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Emergency Contact Phone: {employee.emergencyContactPhone || 'N/A'}</Typography>
            </CardContent>
          </Grid>

          {/* Pay Information */}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Pay Information</Typography>
              <Typography variant="body2" color="textSecondary">Current Hourly Rate: ${employee.currentHourlyRate || 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Current Overtime Rate: ${employee.currentOTRate || 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Current Salary: ${employee.currentSalary || 'N/A'}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="textSecondary">Hire Hourly Rate: ${employee.hireHourlyRate || 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Hire Overtime Rate: ${employee.hireOTRate || 'N/A'}</Typography>
              <Typography variant="body2" color="textSecondary">Hire Salary: ${employee.hireSalary || 'N/A'}</Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EmployeeDetails;
