import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

// Registering a fixed-width font
Font.register({
  family: 'Courier',
  src: 'https://fonts.gstatic.com/s/courierprime/v1/u-450q2lgwslOqpF96syP_PpJBzJxVVmljC43CPMTIM.woff2',
});

// Styles for the PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    height: '100%'
  },
  checkSection: {
    margin: 10,
    padding: 10,
    height: '30%',
  },
  bottomSection: {
    margin: 10,
    padding: 10,
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  topSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: '30%'
  },
  paySection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: '50%',
    fontSize: 9,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  address: {
    flex: .5,
    height: '100%',
    fontSize: 9,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  payOrder: {
    flex: .8,
    display: 'flex',
    flexDirection: 'column',
  },
  payText: {
    width: '100%',
    borderBottom: 1,
    fontSize: 12,
    fontFamily: 'Courier',
  },
  payAmount: {
    flex: .2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Courier-Bold',
    fontSize: 12
  },
  signature: {
    width: '100%',
    alignItems: 'flex-end', 
    fontSize: 8,
    marginTop: 16,
  }
});

const CheckPDF = ({ report = {} }) => {
  const { checkNumber = '', date = '', payee = '', text = '', amount = 0 } = report;

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.checkSection}>
          <View style={styles.topSection}>
            <View style={styles.address}>
              <Text style={{ fontFamily: 'Courier-Bold', fontSize: 11 }}>BUCKEYE HONE COMPANY</Text>
              <Text>P.O. BOX 72</Text>
              <Text>MERCER, PA 16137-0072</Text>
            </View>
            <View style={{ flex: .3, fontSize: 6, fontFamily: 'Courier-Bold', textAlign: 'center' }}>
              <Text>FIRST NATIONAL BANK</Text>
            </View>
            <View style={{ flex: .2, display: 'flex', flexDirection: 'column' }}>
              <View style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>
                <Text>{checkNumber}</Text>
              </View>
              <View style={{ flex: 1, textAlign: 'center', fontSize: 11 }}>
                <Text>DATE {date}</Text>
              </View>
            </View>
          </View>
          <View style={styles.paySection}>
            <View style={styles.payOrder}>
              <View style={{ display: 'flex', flexDirection: 'row', margin: 8 }}>
                <Text style={{ flex: .15 }}>PAY TO THE ORDER OF</Text>
                <View style={{ flex: .85, height: '100%', justifyContent: 'flex-end' }}>
                  <Text style={{ borderBottom: 1, fontSize: 12 }}>{payee}</Text>
                </View>
              </View>
              <View style={{ margin: 8 }}>
                <Text style={styles.payText}>{text}</Text>
              </View>
            </View>
            <View style={styles.payAmount}>
              <Text style={{ fontSize: 12, textAlign: 'right' }}>${amount.toFixed(2)}</Text>
            </View>
          </View>
          <View style={styles.signature}>
            <View style={{ width: '40%', borderTop: 1, textAlign: 'center' }}>
              <Text>AUTHORIZED SIGNATURE</Text>
            </View>
          </View>
        </View>
        <View style={styles.bottomSection}>
          <View style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text style={{ fontFamily: 'Courier-Bold', fontSize: 9 }}>BUCKEYE HONE COMPANY</Text>
            <Text style={{ fontSize: 14, textAlign: 'right' }}>{checkNumber}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CheckPDF;
