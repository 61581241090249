import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import VendorStore from '../stores/VendorStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, List, ListItem, CircularProgress, Snackbar, IconButton,
  ListItemText, ListItemSecondaryAction, TablePagination, useMediaQuery, Button
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import CheckIcon from '@mui/icons-material/Check';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Vendors = observer(() => {
  const navigate = useNavigate();
  const [vendors, setVendors] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    fetchPaginatedVendors();
  }, []);

  const fetchPaginatedVendors = async (page = 1, pageSize = 10, sortBy = 'vendorName', filter = filterCriteria, search = searchQuery) => {
    setLoading(true);
    VendorStore.fetchPaginatedVendors(page, pageSize, sortBy, filter, search).then(() => {
      setVendors(VendorStore.paginatedVendors);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch vendors', severity: 'error' });
      setLoading(false);
    });
  };

  const handleDynamicSearch = (search, filter, sortBy) => {
    setSearchQuery(search);
    setFilterCriteria(filter);
    fetchPaginatedVendors(1, vendors.pageSize, sortBy, filter, search);
  };

  const handleViewVendor = (vendorId) => {
    navigate(`/vendor/${vendorId}`);
  };

  const handleEditVendor = (vendorId) => {
    navigate(`/vendor/edit/${vendorId}`);
  };

  const handleDeleteVendor = async () => {
    if (selectedRows.length > 0) {
      try {
        await Promise.all(selectedRows.map(vendorId => VendorStore.deleteVendor(vendorId)));
        setSnackbar({ open: true, message: 'Vendor(s) deleted successfully', severity: 'success' });
        fetchPaginatedVendors();
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to delete vendor(s)', severity: 'error' });
      }
    }
  };

  const handleAddVendor = () => {
    navigate('/vendor/add');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedVendors(page + 1, vendors.pageSize);
  };

  const handleLimitChange = (event) => {
    fetchPaginatedVendors(1, event.target.value);
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button onClick={() => handleEditVendor(selectedRows[0])} startIcon={<EditIcon />} disabled={selectedRows.length !== 1}>
        Edit
      </Button>
      <Button onClick={handleDeleteVendor} startIcon={<DeleteIcon />} disabled={selectedRows.length === 0}>
        Delete
      </Button>
    </GridToolbarContainer>
  );

  const columns = [
    { field: 'vendorName', headerName: 'Vendor Name', flex: 1, sortable: false },
    { field: 'vendorId', headerName: 'Vendor ID', flex: 1, sortable: false },
    { field: 'vendorOurAcct', headerName: 'Buckeye Account #', flex: 1, sortable: false },
    {
      field: 'active',
      headerName: 'Status',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        params.value ? <CheckIcon /> : null
      )
    },
    { field: 'comments', headerName: 'Comments', flex: 1, sortable: false }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 2 }}>
        <DynamicSearchFilter
          defaultSort="vendorName"
          onSearch={handleDynamicSearch}
          objectInterface={{ vendorName: '', vendorId: '', vendorOurAcct: '' }}
        />
        <IconButton color="primary" onClick={handleAddVendor}>
          <AddIcon />
        </IconButton>
      </Box>

      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {isLargeScreen ? (
              <DataGridPro
                rows={vendors.results}
                columns={columns}
                pageSize={vendors.pageSize}
                rowCount={vendors.totalCount}
                loading={loading}
                autoHeight
                checkboxSelection
                disableColumnFilter
                disableColumnMenu
                disableColumnSort
                onRowClick={(params) => handleViewVendor(params.id)}
                onRowSelectionModelChange={(selection) => handleSelectionChange(selection)}
                getRowId={(row) => row._id}
                components={{
                  Toolbar: CustomToolbar
                }}
                selectionModel={selectedRows}
              />
            ) : (
              <List sx={{ width: '100%' }}>
                {vendors.results.map((vendor) => (
                  <ListItem
                    key={vendor._id}
                    button
                    onClick={() => handleViewVendor(vendor._id)}
                  >
                    <ListItemText
                      primary={vendor.vendorName}
                      secondary={`ID: ${vendor.vendorId} | Account #: ${vendor.vendorOurAcct}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <TablePagination
              component="div"
              count={vendors.totalCount}
              page={vendors.currentPage > 0 ? vendors.currentPage - 1 : 0}
              onPageChange={handlePageChange}
              rowsPerPage={vendors.pageSize}
              onRowsPerPageChange={handleLimitChange}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </>
        )}
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Vendors;
