import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Typography, Button, Grid, IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddressEdit from '../../components/AddressEdit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EmployeeSearch from '../../components/search/EmployeeSearch';
import CustomerStore from '../../stores/CustomerStore';
import CommissionCodeSearch from '../../components/search/CommissionCodeSearch';

const OrderForm = ({ order, onChange, onSubmit }) => {
  const [customerName, setCustomerName] = useState('');
  const [dateEntered, setDateEntered] = useState(new Date());
  const [editingField, setEditingField] = useState(null);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const inputRefs = useRef({});

  useEffect(() => {

    async function fetchCustomerName() {
      if (order.customer) {
        try {
          const customer = await CustomerStore.fetchCustomerById(order.customer);
          const addresses = await CustomerStore.getCustomerShippingAddresses(customer._id);
          setCustomerName(customer.companyName);
          setShippingAddresses(addresses);
        } catch (error) {
          console.error(error);
        }
      }
    }

    fetchCustomerName();
  }, [order.customer]);

  useEffect(() => {
    if (order.enteredDate) {
      setDateEntered(new Date(order.enteredDate));
    }
  }, [order.enteredDate]);

  const calculateTotalCost = (order) => {
    const { totalFeet, costPerFoot, totalPieces, costPerPiece, charges, totalHours, rate, feetPerHour, cutPieces } = order;
    let cost = 0;

    const isNonZero = (value) => value != null && parseFloat(value) !== 0 && value !== '';

    if (isNonZero(totalFeet?.actual) && isNonZero(costPerFoot)) {
      cost += parseFloat(totalFeet?.actual) * parseFloat(costPerFoot);
    }

    if (isNonZero(cutPieces) && isNonZero(costPerPiece)) {
      cost += (parseFloat(cutPieces) * parseFloat(costPerPiece)) || 0;
    } else if (isNonZero(totalPieces?.actual) && isNonZero(costPerPiece)) {
      cost += (parseFloat(totalPieces?.actual) * parseFloat(costPerPiece)) || 0;
    }

    if (isNonZero(charges)) {
      cost += (parseFloat(charges)) || 0;
    }

    return parseFloat(cost).toFixed(2);
  };

  useEffect(() => {
    const totalCost = calculateTotalCost(order);
    onChange({ ...order, total: totalCost });
  }, [order.totalFeet?.actual, order.costPerFoot, order.totalPieces?.actual, order.costPerPiece, order.charges, order.totalHours, order.rate, order.feetPerHour, order.cutPieces]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...order, [name]: value });
  };

  const handleSubChange = (type, event) => {
    onChange({
      ...order,
      [type]: { ...order[type], [event.target.name]: event.target.value },
    });
  };

  const handleCheckboxSubChange = (type, event) => {
    onChange({
      ...order,
      [type]: { ...order[type], [event.target.name]: event.target.checked },
    });
  };

  const handleCommissionChange = (commission) => {
    if (commission) {
      onChange({
        ...order,
        commissionId: commission.commissionCode || '',
        commissionCode: commission._id || '',
      });
    } else {
      onChange({
        ...order,
        commissionId: '',
        commissionCode: null,
      });
    }
  };

  const handleDateChange = (name, date) => {
    const validDate = date instanceof Date && !isNaN(date) ? date : null;
    onChange({ ...order, [name]: validDate });
  };

  const handleShippingAddressChange = (updatedAddress) => {
    setSelectedShippingAddress(updatedAddress);
    onChange({
      ...order,
      shippingAddress: {
        ...updatedAddress.address,
        name: updatedAddress.shippingName || updatedAddress.address.name || updatedAddress.address.businessName,
      },
      shippingAddressId: updatedAddress._id
    });
  };

  const handleBillingAddressChange = (updatedAddress) => {
    onChange({ ...order, billingAddress: updatedAddress });
  };

  const handleAddBundle = () => {
    const newBundle = '';
    onChange({ ...order, bundles: [...order.bundles || [], newBundle] });
  };

  const handleRemoveBundle = (index) => {
    const updatedBundles = order.bundles.filter((_, i) => i !== index);
    onChange({ ...order, bundles: updatedBundles });
  };

  const handleBundleChange = (index, value) => {
    const updatedBundles = [...order.bundles];
    updatedBundles[index] = value;
    onChange({ ...order, bundles: updatedBundles });
  };

  const handleDecimalInputChange = (fieldPath, event) => {
    const { value } = event.target;
    const fieldKeys = fieldPath.split('.');

    const updatedOrder = { ...order };
    let currentLevel = updatedOrder;

    fieldKeys.forEach((key, index) => {
      if (index === fieldKeys.length - 1) {
        currentLevel[key] = value;
      } else {
        if (!currentLevel[key]) {
          currentLevel[key] = {};
        }
        currentLevel = currentLevel[key];
      }
    });

    onChange(updatedOrder);
  };

  const handleFocus = (field) => {
    setEditingField(field);
  };

  const handleBlur = (fieldPath, decimalPlaces = 3) => {
    setEditingField(null);

    const fieldKeys = fieldPath.split('.');
    const updatedOrder = { ...order };
    let currentLevel = updatedOrder;

    fieldKeys.forEach((key, index) => {
      if (index === fieldKeys.length - 1) {
        const value = parseFloat(currentLevel[key]).toFixed(decimalPlaces);
        currentLevel[key] = isNaN(value) ? decimalPlaces === 3 ? '0.000' : '0.00' : value;
      } else {
        if (!currentLevel[key]) {
          currentLevel[key] = {};
        }
        currentLevel = currentLevel[key];
      }
    });

    onChange(updatedOrder);
  };

  const handleKeyPress = (event, field, decimalPlaces = 3) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleBlur(field, decimalPlaces);
    }
  };

  const validInitialDates = {
    dueDate: order.dueDate ? new Date(order.dueDate) : null,
    promiseDate: order.promiseDate ? new Date(order.promiseDate) : null,
    shipDate: order.shipDate ? new Date(order.shipDate) : null,
    receivedDate: order.receivedDate ? new Date(order.receivedDate) : null,
    finishedDate: order.finishedDate ? new Date(order.finishedDate) : null,
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Order Details</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2}>
          {/* General Information */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>General Information</Typography>
            <TextField label="Shop Order #" name="salesOrderNumber" fullWidth value={order.salesOrderNumber} onChange={handleChange} margin="normal" />
            <TextField label="Purchase Order #" name="customerPONumber" fullWidth value={order.customerPONumber} onChange={handleChange} margin="normal" />
            <TextField label="Mill/Job #" name="millJobNumber" fullWidth value={order.millJobNumber} onChange={handleChange} margin="normal" />
            <CommissionCodeSearch handleChange={handleCommissionChange} initialCommissionCode={order.commissionCode?._id || order.commissionId} label="Commission ID" marginTop={2} />
            <TextField label="Customer Name" name="customerName" disabled fullWidth value={customerName} margin="normal" />
            <FormControlLabel
              control={
                <Checkbox
                  checked={order.isCancelled}
                  onChange={(event) => handleChange({ target: { name: 'isCancelled', value: event.target.checked } })}
                  name="isCancelled"
                />
              }
              label="Cancelled?"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>Order Timeline</Typography>
            <DatePicker sx={{ m: 2 }} label="Date Entered" name="dateEntered" value={dateEntered} onChange={(date) => handleDateChange('enteredDate', date)} />
            <DatePicker sx={{ m: 2 }} label="Date Received" name="dateReceived" value={validInitialDates.receivedDate} onChange={(date) => handleDateChange('receivedDate', date)} />
            <DatePicker sx={{ m: 2 }} label="Finish Date" name="finishDate" value={validInitialDates.finishedDate} onChange={(date) => handleDateChange('finishedDate', date)} />
            <DatePicker sx={{ m: 2 }} label="Due Date" name="dueDate" value={validInitialDates.dueDate} onChange={(date) => handleDateChange('dueDate', date)} />
            <DatePicker sx={{ m: 2 }} label="Promise Date" name="promiseDate" value={validInitialDates.promiseDate} onChange={(date) => handleDateChange('promiseDate', date)} />
            <DatePicker sx={{ m: 2 }} label="Ship Date" name="shipDate" value={validInitialDates.shipDate} onChange={(date) => handleDateChange('shipDate', date)} />
          </Grid>
          {/* Remaining form fields go here */}
          {/* Billing and Shipping Address */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>Billing Address</Typography>
            <TextField label="POC for Billing" name="pocBilling" fullWidth value={order.pocBilling} onChange={handleChange} margin="normal" variant="outlined" placeholder="John Smith" />
            <AddressEdit
              address={order.billingAddress}
              onChange={handleBillingAddressChange}
              fieldNames={{ name: "name", street1: "street", street2: "line2", city: "city", state: "state", zip: "postalCode", country: "country" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>Shipping Address</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="POC for Shipping"
                  name="pocShipping"
                  fullWidth
                  value={order.pocShipping}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  placeholder="John Smith"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="shipping-label">Select Shipping Address</InputLabel>
                  <Select
                    labelId="shipping-label"
                    value={selectedShippingAddress}
                    onChange={(e) => handleShippingAddressChange(e.target.value)}
                    label="Select Shipping Address"
                  >
                    {shippingAddresses.map((addr) => (
                      <MenuItem key={addr._id} value={addr}>
                        {addr.shippingName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <AddressEdit
              address={order.shippingAddress}
              onChange={handleShippingAddressChange}
              fieldNames={{ name: "name", street1: "street", street2: "line2", city: "city", state: "state", zip: "postalCode", country: "country" }}
            />
          </Grid>
          {/* Product and Manufacturing Details */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>Product and Manufacturing Details</Typography>
            <TextField
              label="Tube Outer Diameter"
              name="tubeOuterDiameter"
              fullWidth
              value={order.tubeOuterDiameter}
              onChange={(e) => handleDecimalInputChange('tubeOuterDiameter', e)}
              onFocus={() => handleFocus('tubeOuterDiameter')}
              onBlur={() => handleBlur('tubeOuterDiameter')}
              onKeyDown={(e) => handleKeyPress(e, 'tubeOuterDiameter')}
              margin="normal"
              InputProps={{
                style: {
                  borderColor: editingField === 'tubeOuterDiameter' ? 'red' : '',
                }
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Hone ID Lower"
                  name="lowerLimit"
                  fullWidth
                  value={order.honeInnerDiameter.lowerLimit}
                  onChange={(e) => handleDecimalInputChange('honeInnerDiameter.lowerLimit', e)}
                  onFocus={() => handleFocus('honeInnerDiameter.lowerLimit')}
                  onBlur={() => handleBlur('honeInnerDiameter.lowerLimit')}
                  onKeyDown={(e) => handleKeyPress(e, 'honeInnerDiameter.lowerLimit')}
                  margin="normal"
                  InputProps={{
                    style: {
                      borderColor: editingField === 'honeInnerDiameter.lowerLimit' ? 'red' : '',
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Hone ID Upper"
                  name="upperLimit"
                  fullWidth
                  value={order.honeInnerDiameter.upperLimit}
                  onChange={(e) => handleDecimalInputChange('honeInnerDiameter.upperLimit', e)}
                  onFocus={() => handleFocus('honeInnerDiameter.upperLimit')}
                  onBlur={() => handleBlur('honeInnerDiameter.upperLimit')}
                  onKeyDown={(e) => handleKeyPress(e, 'honeInnerDiameter.upperLimit')}
                  margin="normal"
                  InputProps={{
                    style: {
                      borderColor: editingField === 'honeInnerDiameter.upperLimit' ? 'red' : '',
                    }
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label="Act ID"
              name="actualInnerDiameter"
              fullWidth
              value={order.actualInnerDiameter}
              onChange={(e) => handleDecimalInputChange('actualInnerDiameter', e)}
              onFocus={() => handleFocus('actualInnerDiameter')}
              onBlur={() => handleBlur('actualInnerDiameter')}
              onKeyDown={(e) => handleKeyPress(e, 'actualInnerDiameter')}
              margin="normal"
              InputProps={{
                style: {
                  borderColor: editingField === 'actualInnerDiameter' ? 'red' : '',
                }
              }}
            />
            <TextField
              label="Stock Removal"
              name="stockRemoval"
              fullWidth
              value={order.stockRemoval}
              onChange={(e) => handleDecimalInputChange('stockRemoval', e)}
              onFocus={() => handleFocus('stockRemoval')}
              onBlur={() => handleBlur('stockRemoval')}
              onKeyDown={(e) => handleKeyPress(e, 'stockRemoval')}
              margin="normal"
              variant="outlined"
              InputProps={{
                style: {
                  borderColor: editingField === 'stockRemoval' ? 'red' : '',
                }
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="surface-finish-label"> Surface Finish </InputLabel>
                  <Select
                    labelId="surface-finish-label"
                    value={order.SurfaceFinish?.Type || 'RQ'}
                    label="Surface Finish"
                    name="Type"
                    onChange={(e) => handleSubChange("SurfaceFinish", e)}
                  >
                    <MenuItem value="RQ">RQ</MenuItem>
                    <MenuItem value="RA">RA</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Value"
                  name="Value"
                  fullWidth
                  value={order.SurfaceFinish?.Value}
                  onChange={(e) => handleSubChange("SurfaceFinish", e)}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>Theoretical Fields (Quantity)</Typography>
            <TextField
              label="Total Feet Theoretical"
              name="theoretical"
              fullWidth
              value={order.totalFeet?.theoretical}
              onChange={(e) => handleDecimalInputChange('totalFeet.theoretical', e)}
              onFocus={() => handleFocus('totalFeet.theoretical')}
              onBlur={() => handleBlur('totalFeet.theoretical', 2)}
              onKeyDown={(e) => handleKeyPress(e, 'totalFeet.theoretical', 2)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Tube Length Theoretical"
              name="theoretical"
              fullWidth
              value={order.tubeLength?.theoretical}
              onChange={(e) => handleDecimalInputChange('tubeLength.theoretical', e)}
              onFocus={() => handleFocus('tubeLength.theoretical')}
              onBlur={() => handleBlur('tubeLength.theoretical', 2)}
              onKeyDown={(e) => handleKeyPress(e, 'tubeLength.theoretical', 2)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Total Pieces Theoretical"
              name="theoretical"
              fullWidth
              value={order.totalPieces?.theoretical}
              onChange={(e) => handleSubChange('totalPieces', e)}
              margin="normal"
              variant="outlined"
            />

            <Typography variant="subtitle1" gutterBottom>Actual Fields</Typography>
            <TextField
              label="Total Feet Actual"
              name="actual"
              fullWidth
              value={order.totalFeet?.actual}
              onChange={(e) => handleDecimalInputChange('totalFeet.actual', e)}
              onFocus={() => handleFocus('totalFeet.actual')}
              onBlur={() => handleBlur('totalFeet.actual', 2)}
              onKeyDown={(e) => handleKeyPress(e, 'totalFeet.actual', 2)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Tube Length Actual"
              name="actual"
              fullWidth
              value={order.tubeLength?.actual}
              onChange={(e) => handleDecimalInputChange('tubeLength.actual', e)}
              onFocus={() => handleFocus('tubeLength.actual')}
              onBlur={() => handleBlur('tubeLength.actual', 2)}
              onKeyDown={(e) => handleKeyPress(e, 'tubeLength.actual', 2)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Total Pieces Actual"
              name="actual"
              fullWidth
              value={order.totalPieces?.actual}
              onChange={(e) => handleSubChange('totalPieces', e)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="No. of Bundles"
              name="bundleCount"
              fullWidth
              value={order.bundleCount}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Weight"
              name="weight"
              fullWidth
              value={order.weight}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            />

          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>Additional Product Details</Typography>
            <TextField label="Cut Pieces" name="cutPieces" fullWidth value={order.cutPieces} onChange={handleChange} margin="normal" />
            <TextField label="Cut On Average Length" name="cutOAL" fullWidth value={order.cutOAL} onChange={handleChange} margin="normal" />
            <TextField label="On Average Length" name="OAL" fullWidth value={order.OAL} onChange={handleChange} margin="normal" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField label="Plugs" name="Count" fullWidth value={order.Plugs?.Count} onChange={(e) => handleSubChange('Plugs', e)} margin="normal" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <FormControlLabel label="None" control={<Checkbox name="noPlugs" checked={order.Plugs?.noPlugs} onChange={(e) => handleCheckboxSubChange('Plugs', e)} />} />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Financial Information */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>Product Rates</Typography>
            <TextField label="Rate" name="rate" fullWidth value={order.rate} onChange={handleChange} margin="normal" />
            <TextField label="Feet per Hour" name="feetPerHour" fullWidth value={order.feetPerHour} onChange={handleChange} margin="normal" />
            <TextField label="Total Hours" name="totalHours" fullWidth value={order.totalHours} onChange={handleChange} margin="normal" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>Costs</Typography>
            <TextField
              label="Cost Per Foot"
              name="costPerFoot"
              fullWidth
              value={order.costPerFoot}
              onChange={(e) => handleDecimalInputChange('costPerFoot', e)}
              onFocus={() => handleFocus('costPerFoot')}
              onBlur={() => handleBlur('costPerFoot', 2)}
              onKeyDown={(e) => handleKeyPress(e, 'costPerFoot', 2)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
            <TextField
              label="Cost Per Piece"
              name="costPerPiece"
              fullWidth
              value={order.costPerPiece}
              onChange={(e) => handleDecimalInputChange('costPerPiece', e)}
              onFocus={() => handleFocus('costPerPiece')}
              onBlur={() => handleBlur('costPerPiece', 2)}
              onKeyDown={(e) => handleKeyPress(e, 'costPerPiece', 2)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
            <TextField
              label="Other Charges"
              name="charges"
              fullWidth
              value={order.charges}
              onChange={(e) => handleDecimalInputChange('charges', e)}
              onFocus={() => handleFocus('charges')}
              onBlur={() => handleBlur('charges', 2)}
              onKeyDown={(e) => handleKeyPress(e, 'charges', 2)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
            <TextField
              label="Total Cost"
              name="totalCost"
              fullWidth
              value={order.total}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </Grid>

          {/* Bundle Information */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>Bundle Information</Typography>
            {order.bundles && order.bundles.map((bundle, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                  label="Bundle"
                  name="bundle"
                  fullWidth
                  value={bundle}
                  onChange={(e) => handleBundleChange(index, e.target.value)}
                  margin="normal"
                />
                <IconButton onClick={() => handleRemoveBundle(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Box>
            ))}
            <IconButton onClick={handleAddBundle}><AddCircleOutlineIcon /></IconButton>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Special Instructions"
              name="specialInstructions"
              fullWidth
              value={order.specialInstructions}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              multiline
              rows={4} // Adjust the number of rows as needed
              inputProps={{ style: { resize: 'vertical' } }}
              helperText="Prints on job sheet (to shop)"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Comments"
              name="comments"
              fullWidth
              value={order.comments}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              multiline
              rows={4} // Adjust the number of rows as needed
              inputProps={{ style: { resize: 'vertical' } }}
              helperText="Prints on invoices (to customer)"
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
      </Box>
    </Box>
  );
}

export default OrderForm;
