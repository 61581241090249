import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EmployeeStore from '../../stores/EmployeeStore';
import EmployeeForm from './EmployeeForm';
import { Box, Typography, CircularProgress, Button, IconButton, Menu, MenuItem, Snackbar, Alert } from '@mui/material';
import UserSearch from '../../components/search/UserSearch';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const EditEmployee = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  useEffect(() => {
    setLoading(true);

    EmployeeStore.fetchEmployeeById(employeeId).then(() => {
      setEmployee(EmployeeStore.employee);
      setLoading(false);
    }).catch((error) => {
      console.error('Failed to fetch employee:', error);
      setSnackbar({ open: true, message: 'Failed to fetch employee', severity: 'error' });
    });

  }, [employeeId]);

  const handleSubmit = async () => {
    try {
      await EmployeeStore.updateEmployee(employeeId, employee);
      navigate('/employee');
    } catch (error) {
      console.error('Failed to update employee:', error);
      setSnackbar({ open: true, message: 'Failed to update employee', severity: 'error' });
    }
  };

  const handleDelete = async () => {
    try {
      await EmployeeStore.deleteEmployee(employeeId);
      navigate('/employee');
    } catch (error) {
      console.error('Failed to delete employee:', error);
      setSnackbar({ open: true, message: 'Failed to delete employee', severity: 'error' });
    }
  };

  const handleChange = (updatedEmployee) => {
    setEmployee(updatedEmployee);
  };

  const handleUserChange = (user) => {
    if (user) {
      setEmployee({
        ...employee,
        user: user._id
      });
    }
  }

  const handleBack = () => {
    navigate('/employee');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAction = (action) => {
    switch (action) {
      case 'Edit':
        navigate(`/employee/${employeeId}`)
        break;
      case 'Delete':
        console.log('Delete action');
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Edit Employee</Typography>
        <Box>
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuAction('Edit')}>View</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </Box>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        employee &&
        <div>
          <Box m={2}>
            <Typography variant="h6" ml={1} mb={0} sx={{ mb: 3 }}>{`Select a user (optional)`}</Typography>
            <UserSearch margin={1} handleChange={handleUserChange} initialUserId={employee.user} />
          </Box>
          <EmployeeForm employee={employee} onSubmit={handleSubmit} onDelete={handleDelete} back={handleBack} onChange={handleChange} />
          <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        </div>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditEmployee;
