import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import MachineStore from '../../stores/MachineStore';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import {
  Box, Typography, List, ListItem, ListItemText, Paper,
  Button, Snackbar, IconButton, ListItemSecondaryAction,
  TablePagination, useMediaQuery
} from '@mui/material';
import { format } from 'date-fns';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const filterObject = {
  // Add other fields as necessary
};

const MachineOperators = observer(() => {
  const [machineOperators, setMachineOperators] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchPaginatedMachines();
  }, [searchQuery, filterCriteria]);

  const fetchPaginatedMachines = async (page = 1, pageSize = 10, sortBy = 'name', filter = filterCriteria, search = searchQuery) => {
    setLoading(true);
    try {
      await MachineStore.fetchPaginatedMachines(page, pageSize, sortBy, filter, search);
      if (MachineStore.paginatedMachines) {
        setMachineOperators(MachineStore.paginatedMachines);
      } else {
        setMachineOperators({
          hasMore: false,
          totalCount: 0,
          currentPage: 0,
          pageSize: 10,
          totalPages: 0,
          results: []
        });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch machine operators', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleViewMachineOperator = (machineId) => {
    localStorage.setItem('orderTab', 4);
    navigate(`/machine/${machineId}`);
  };

  const handleEditMachineOperator = (machineId) => {
    localStorage.setItem('orderTab', 4);
    navigate(`/machine/edit/${machineId}`);
  }

  const handleEdit = () => {
    navigate(`/machine/edit/${selectedRows[0]}`)
  }

  const handleCreateMachineOperator = () => {
    localStorage.setItem('orderTab', 4);
    navigate('/machine/add');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedMachines(page + 1, machineOperators.pageSize);
  };

  const handleLimitChange = (event) => {
    fetchPaginatedMachines(1, event.target.value);
  };

  const handleDynamicSearch = (search, filter, sortBy) => {
    const searchFilter = {
      $or: [
        { operators: { $regex: search, $options: 'i' } },
        { name: { $regex: search, $options: 'i' } }
      ]
    }

    setFilterCriteria(searchFilter);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button onClick={handleEdit} startIcon={<EditIcon />} disabled={selectedRows.length !== 1}>
        Edit
      </Button>
      {/* <Button onClick={handleDeleteOrder} startIcon={<DeleteIcon />} disabled={selectedRows.length === 0}>
        Delete
      </Button> */}
    </GridToolbarContainer>
  );

  const columns = [
    { field: 'name', headerName: 'Machine Number', flex: .5 },
    { field: 'operators', headerName: 'Operators', flex: 1, },
    { field: 'status', headerName: 'Status', flex: .5 },
    {
      field: 'lastMaintenanceDate',
      headerName: 'Last Maintenance',
      flex: 1,
      renderCell: (params) => params.row.lastMaintenanceDate ? format(new Date(params.row.lastMaintenanceDate), 'MM/dd/yyyy') : 'N/A'
    },
    {
      field: 'nextMaintenanceDate',
      headerName: 'Next Maintenance',
      flex: 1,
      renderCell: (params) => params.row.nextMaintenanceDate ? format(new Date(params.row.nextMaintenanceDate), 'MM/dd/yyyy') : 'N/A'
    },
  ];

  const renderEmptyState = () => (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <InfoIcon color="disabled" sx={{ fontSize: 60 }} />
      <Typography variant="subtitle1">No machine operators available, create a new machine operator...</Typography>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 2 }}>
        <DynamicSearchFilter
          defaultSort="name"
          onSearch={handleDynamicSearch}
          objectInterface={filterObject}
        />
        <IconButton color="primary" onClick={handleCreateMachineOperator}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box>
        {machineOperators.totalCount !== 0 ? (
          <>
            {isLargeScreen ? (
              <DataGridPro
                rows={machineOperators.results}
                columns={columns}
                pageSize={machineOperators.pageSize}
                rowCount={machineOperators.totalCount}
                loading={loading}
                autoHeight
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={(params) => handleViewMachineOperator(params.id)}
                onRowSelectionModelChange={(selection) => setSelectedRows(selection)}
                slots={{
                  toolbar: CustomToolbar
                }}
                getRowId={(row) => row._id}
                rowSelectionModel={selectedRows}
              />
            ) : (
              <Paper sx={{ p: 2 }}>
                <List>
                  {machineOperators.results.map((machineOperator, index) => (
                    <ListItem sx={{ m: 2 }} button key={index} onClick={() => handleEditMachineOperator(machineOperator._id)}>
                      <ListItemText
                        primary={`${machineOperator.name || ''} ${machineOperator.operators.join(', ') || ''}`}
                        secondary={`${machineOperator.status || ''}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
            <TablePagination
              component="div"
              count={machineOperators.totalCount}
              page={machineOperators.currentPage > 0 ? machineOperators.currentPage - 1 : 0}
              onPageChange={handlePageChange}
              rowsPerPage={machineOperators.pageSize}
              onRowsPerPageChange={handleLimitChange}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{ mt: 1 }}
            />
          </>
        ) : (
          renderEmptyState()
        )}
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default MachineOperators;
