import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VendorStore from "../../stores/VendorStore";
import VendorForm from "./VendorForm";
import {
  Box, Typography, CircularProgress, Snackbar, Alert, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Button
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';

const EditVendor = () => {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });

  useEffect(() => {
    setLoading(true);
    VendorStore.fetchVendorById(vendorId)
      .then(() => {
        setVendor(VendorStore.vendor);
        setLoading(false);
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Failed to fetch vendor', severity: 'error' });
        setLoading(false);
      });
  }, [vendorId]);

  const handleSubmit = async (updatedVendor) => {
    try {
      await VendorStore.updateVendor(vendorId, updatedVendor);
      navigate("/vendors", { state: { message: 'Vendor updated successfully', severity: 'success' } });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to update vendor', severity: 'error' });
    }
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await VendorStore.deleteVendor(vendorId);
      navigate("/vendors", { state: { message: 'Vendor deleted successfully', severity: 'success' } });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to delete vendor', severity: 'error' });
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleBack = () => {
    navigate("/vendors");
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "" });
  };

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>Edit Vendor</Typography>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <IconButton onClick={() => navigate(`/vendor/${vendorId}`)}>
          <CloseIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        vendor && (
          <VendorForm
            initialVendorData={vendor}
            onSubmit={handleSubmit}
            back={handleBack}
          />
        )
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Vendor</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this vendor?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditVendor;
