import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Select, MenuItem, FormControl, Typography
} from '@mui/material';
import OpenInvoiceReport from './OpenInvoiceReport';
import AccountCodesReport from './AccountCodesReport';
import PayablesByAccountReport from './PayablesByAccount';
import PayablesByVendorReport from './PayablesByVendorReport';
import AccountsPayableReport from './AccountsPayableReport';
import CheckRegisterReport from './CheckRegisterReport';

const Payables = () => {
  const [report, setReport] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const reportParam = searchParams.get('report');

    switch (reportParam) {
      case 'openInvoice':
        setReport(0);
        break;
      case 'accountCodes':
        setReport(1);
        break;
      case 'payablesByAccount':
        setReport(2);
        break;
      case 'payablesByVendor':
        setReport(3);
        break;
      case 'accountsPayable':
        setReport(4);
        break;
      case 'checkRegister':
        setReport(5);
        break;
      default:
        setReport(0);
    }
  }, [location.search]);

  const handleReportChange = (event) => {
    const selectedReport = event.target.value;
    setReport(selectedReport);

    const reportNames = [
      'openInvoice',
      'accountCodes',
      'payablesByAccount',
      'payablesByVendor',
      'accountsPayable',
      'checkRegister'
    ];

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('report', reportNames[selectedReport]);

    navigate(`?${searchParams.toString()}`);
  };

  const renderReport = () => {
    switch (report) {
      case 0:
        return <OpenInvoiceReport />;
      case 1:
        return <AccountCodesReport />;
      case 2:
        return <PayablesByAccountReport />;
      case 3:
        return <PayablesByVendorReport />;
      case 4:
        return <AccountsPayableReport />;
      case 5:
        return <CheckRegisterReport />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2, marginTop: 4 }}>
        <FormControl fullWidth>
          <Typography variant='caption' sx={{ ml: 2, my: 1 }}>Select Report</Typography>
          <Select
            labelId="payables-report-select-label"
            value={report}
            onChange={handleReportChange}
          >
            <MenuItem value={0}>Open Invoice</MenuItem>
            <MenuItem value={1}>Account Codes</MenuItem>
            <MenuItem value={2}>Payables By Account</MenuItem>
            <MenuItem value={3}>Payables By Vendor</MenuItem>
            <MenuItem value={4}>Accounts Payable</MenuItem>
            <MenuItem value={5}>Check Register</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderReport()}
    </Box>
  );
};

export default Payables;