import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCodeStore from '../../stores/AccountCodeStore';
import AccountCodeForm from './AccountCodeForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';

const AddAccountCode = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const [accountCode, setAccountCode] = useState({
    accountCode: '',
    accountBase: '',
    accountDescription: '',
    accountIncr: 0,
    accountType: '',
    taxLine: '',
    isActive: true,
    website: '',
    contact: '',
    comments: ''
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await AccountCodeStore.createAccountCode(accountCode);
      navigate('/account-codes');
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to create account code', severity: 'error' });
      setLoading(false);
    }
  };

  const handleChange = (updatedAccountCode) => {
    setAccountCode(updatedAccountCode);
  };

  const handleBack = () => {
    navigate('/account-codes');
  };

  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h4">Add New Account Code</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <AccountCodeForm accountCode={accountCode} onSubmit={handleSubmit} back={handleBack} onChange={handleChange} />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddAccountCode;
