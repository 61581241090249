import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomerStore from '../../stores/CustomerStore';
import ShopOrdersStore from "../../stores/ShopOrdersStore";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Modal,
  Paper,
  Badge
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import { PDFViewer } from "@react-pdf/renderer";
import JobSheetsPDF from "../../pdf/JobSheetsPDF";
import DailyMachineSheetPDF from "../../pdf/DailyMachineSheetPDF";
import JobStore from "../../stores/JobStore";

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [viewJobSheet, setViewJobSheet] = useState(false);
  const [viewMachineSheet, setViewMachineSheet] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [productionsEnabled, setProductionEnabled] = useState(false);
  const { orderId } = useParams();

  useEffect(() => {
    ShopOrdersStore.fetchShopOrderById(orderId).then((fetchedOrder) => {
      if (fetchedOrder) {
        setOrder(fetchedOrder);
      } else {
        console.error("Fetched order is not valid");
        setOrder(null);
      }
    });
  }, [orderId]);

  useEffect(() => {
    if (order && order.customer) {
      async function fetchCustomer() {
        const customerData = await CustomerStore.fetchCustomerById(order.customer);
        setCustomer(customerData.companyName);
      }

      async function fetchProductions() {
        const jobFilter = { orderId: orderId };
        const jobs = await JobStore.fetchPaginatedJobs(1, 10, '-dueDate', jobFilter);

        if (jobs && jobs.results.length > 0) {
          setProductionEnabled(true);
        } else {
          setProductionEnabled(false);
        }

      }

      fetchCustomer();
      fetchProductions();
    }
  }, [order]);

  if (!order) {
    return <CircularProgress />;
  }

  const handleEdit = () => {
    navigate(`/orders/edit/${orderId}`);
  };

  const handleBack = () => {
    navigate("/orders");
  };

  const handleProductionsClick = () => {
    navigate(`/productions`, { state: { orderId: orderId } });
  }

  const formatDate = (date) => {
    return date
      ? new Date(date).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
      : "N/A";
  };

  const formatNumber = (number) => {
    const parsedNumber = parseFloat(number);
    return !isNaN(parsedNumber) ? parsedNumber.toFixed(3) : "N/A";
  };

  const renderDetail = (label, value, isNumber = false) => (
    <ListItem>
      <ListItemText primary={label} secondary={isNumber ? (value !== null && value !== undefined ? formatNumber(value) : "N/A") : (value !== null && value !== undefined ? value : "N/A")} />
    </ListItem>
  );

  const renderPlugs = (label, value) => (
    <ListItem>
      <ListItemText primary={label} secondary={value.noPlugs ? 'False' : value.Count} />
    </ListItem>
  );

  const goToInvoice = () => {
    navigate(`/receivables/edit/new`, { state: { orderId: orderId } });
  }

  const calculateTotalCost = (order) => {
    const { totalFeet, costPerFoot, totalPieces, costPerPiece, charges } = order;
    let cost = 0;

    if (totalFeet?.actual && costPerFoot) {
      cost += parseFloat(totalFeet.actual) * costPerFoot;
    }

    if (totalPieces?.actual && costPerPiece) {
      cost += parseFloat(totalPieces.actual) * costPerPiece;
    }

    if (charges) {
      cost += charges;
    }

    return cost.toFixed(2);
  };

  const totalCost = calculateTotalCost(order);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack}>
            <BackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ ml: 2 }}>
            Order Details - {order?.salesOrderNumber}
          </Typography>
        </Box>

        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Box>

      <Box display="flex" sx={{ gap: 2, alignItems: 'center', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Badge
            badgeContent={JobStore.paginatedJobs?.totalCount || 0}
            color={JobStore.paginatedJobs?.totalCount > 0 ? 'success' : 'secondary'}
            invisible={!productionsEnabled || JobStore.paginatedJobs?.totalCount === 0}
          >
            <Button
              variant="text"
              onClick={() => handleProductionsClick()}
              disabled={!productionsEnabled}
            >
              Productions
            </Button>
          </Badge>
        </Box>

        <Button
          variant="text"
          onClick={() => setViewJobSheet(true)}
        >
          Job Sheet
        </Button>

        <Button
          variant="text"
          onClick={() => setViewMachineSheet(true)}
        >
          Machine Production Sheet
        </Button>
        <Button
          variant="text"
          onClick={() => goToInvoice()}
        >
          Invoice
        </Button>
      </Box>

      <Grid container spacing={3}>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            General Information
          </Typography>
          {renderDetail("Shop Order #", order.salesOrderNumber)}
          {renderDetail("Purchase Order #", order.customerPONumber)}
          {renderDetail("Mill/Job #", order.millJobNumber)}
          {renderDetail("Commission ID", order.commissionCode ? order.commissionCode.commissionDescription : "No Commission Available")}
          {renderDetail("Customer Name", order.customerName)}
          {renderDetail("Cancelled", order.isCancelled ? "Yes" : "No")}
          {renderDetail("Due Date", formatDate(order.dueDate))}
          {renderDetail("Promise Date", formatDate(order.promiseDate))}
          {renderDetail("Ship Date", formatDate(order.shipDate))}
          {renderDetail("Date Entered", formatDate(order.enteredDate))}
          {renderDetail("Date Received", formatDate(order.receivedDate))}
          {renderDetail("Finish Date", formatDate(order.finishedDate))}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Product and Manufacturing Details
          </Typography>
          {renderDetail("Tube OD", order.tubeOuterDiameter, true)}
          {renderDetail("Actual Inner Diameter", order.actualInnerDiameter, true)}
          {renderDetail("Stock Removal", order.stockRemoval, true)}
          {renderDetail("Total Pieces", order.totalPieces?.actual)}
          {renderDetail("Number of Bundles", order.bundleCount)}
          {renderDetail("Cut Pieces", order.cutPieces)}
          {renderDetail("Cut On Average Length", order.cutOAL)}
          {renderDetail("On Average Length", order.OAL)}
          {renderDetail("Banding Clips", order.bandingClips)}
          {renderPlugs("Plugs", order.Plugs)}
          {renderDetail(
            "Hone Inner Diameter (Lower)",
            order.honeInnerDiameter?.lowerLimit,
            true
          )}
          {renderDetail(
            "Hone Inner Diameter (Upper)",
            order.honeInnerDiameter?.upperLimit,
            true
          )}
        </Grid>


        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Cost Information
          </Typography>
          {renderDetail("Rate", order.rate)}
          {renderDetail("Feet per Hour", order.feetPerHour)}
          {renderDetail("Total Hours", order.totalHours)}
          {renderDetail("Cost Per Foot", order.costPerFoot)}
          {renderDetail("Cost Per Piece", order.costPerPiece)}
          {renderDetail("Charges", order.charges)}
          {renderDetail("Total Feet (Estimate)", order.totalFeet.theoretical)}
          {renderDetail("Total Feet (Actual)", order.totalFeet.actual)}
          {renderDetail("Tube Length (Estimate)", order.tubeLength.theoretical)}
          {renderDetail("Tube Length (Actual)", order.tubeLength.actual)}
          {renderDetail("Rq", order.SurfaceFinish?.Value)}
          {renderDetail("Weight", order.weight)}
          {renderDetail("Special Instructions", order.specialInstructions)}
          {renderDetail("Comments", order.comments)}
          {renderDetail("Total Cost", '$' + totalCost)}
        </Grid>

        {/* Shipping Address */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Shipping Address
          </Typography>
          {renderDetail("Business Name", order.shippingAddress.name || order.shippingAddress.businessName)}
          {renderDetail("Street", order.shippingAddress.street)}
          {renderDetail("City", order.shippingAddress.city)}
          {renderDetail("State", order.shippingAddress.state)}
          {renderDetail("Postal Code", order.shippingAddress.postalCode)}
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Button variant="contained" onClick={handleEdit}>
          Edit
        </Button>
        <Button variant="outlined" onClick={handleBack}>
          Back to Orders
        </Button>
      </Box>
      {order && (
        <>
          {viewJobSheet && (
            <Modal
              open={viewJobSheet}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={() => setViewJobSheet(false)}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width="100%" height="100%">
                  <JobSheetsPDF orderInfo={order} />
                </PDFViewer>
              </Paper>
            </Modal>
          )}
          {viewMachineSheet && (
            <Modal
              open={viewMachineSheet}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={() => setViewMachineSheet(false)}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width="100%" height="100%">
                  <DailyMachineSheetPDF orderInfo={order} />
                </PDFViewer>
              </Paper>
            </Modal>
          )}
        </>
      )}
    </Box>
  );
};

export default OrderDetails;
