import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Help = () => {
  const faqs =  [
    { question: "How do I reset my password?", answer: "To reset your password, go to the preferences page and follow the password reset instructions." },
    { question: "How can I contact customer support?", answer: "You can contact customer support via email at support@example.com or by calling our helpline." },

    // Shop Orders FAQs
    { question: "How do I create a new shop order?", answer: "To create a new shop order, navigate to the Shop Orders section and click on 'Create New Order'." },
    { question: "How do I track the status of a shop order?", answer: "You can track a shop order's status in the Shop Orders section. Details include its current stage and any updates." },
    { question: "Can I edit an existing shop order?", answer: "Yes, you can edit shop orders depending on their status. See the Shop Orders section for details on modifying orders." },

    // Employees FAQs
    { question: "How do I add a new employee?", answer: "To add an employee, go to the Employees section and click on 'Add New Employee'."},
    { question: "How do I view an employee's timesheet?", answer: "Employee timesheets can be found within their profile in the Employees section."},
    { question: "How do I manage employee permissions?", answer: "Employee permissions can be configured in the Employees section, under the 'Roles' tab."},

    // Reports FAQs
    { question: "What types of reports can I generate?", answer: "You can generate reports on inventory levels, sales performance, and production efficiency."},
    { question: "How do I customize a report?", answer: "Reports can be customized by filtering data, selecting date ranges, and choosing the desired output format."},
    { question: "Can I schedule reports to be generated automatically?", answer: "Yes, report scheduling is available. See the Reports section for instructions."},

    // Receivables (Invoices) FAQs
    {
      question: "How do I manage invoices in Receivables?",
      answer: "In Receivables: Use filters to view an invoice, click 'Edit' to modify, and select 'Print' to print the invoice.",
    },

  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Help & Support</Typography>

      <Typography variant="h5" sx={{ mb: 2 }}>Frequently Asked Questions</Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button 
        variant="contained" 
        color="primary" 
        sx={{ mt: 4 }}
        onClick={() => window.location.href = '/contact-support'} // Replace with your actual support link
      >
        Get Support
      </Button>
    </Box>
  );
};

export default Help;
