import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Paper, Divider, IconButton,
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button,
  Menu, MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MachineStore from '../../stores/MachineStore';
import DataContainer from '../../components/DataContainer';
import CloseIcon from '@mui/icons-material/Close';

const MachineDetails = () => {
  const { machineId } = useParams();
  const [machine, setMachine] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/orders');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/machine/edit/${machineId}`);
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    // Perform delete action here
    setDeleteDialogOpen(false);
    MachineStore.deleteMachine(machineId).then(() => {
      navigate('/orders');
    });
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    const fetchMachine = async () => {
      try {
        const fetchedMachine = await MachineStore.fetchMachineById(machineId);
        setMachine(fetchedMachine);
      } catch (error) {
        console.error('Error fetching Machine:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMachine();
  }, [machineId]);

  return (
    <Paper elevation={3} sx={{ p: 4, mx: 'auto', my: 2, maxWidth: '100% ' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>Machine Information
        </Typography>
        <Box>
          <IconButton
            aria-label="more"
            aria-controls="machine-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="machine-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
          <IconButton onClick={handleBack}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ m: 3 }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }} >
              <DataContainer label="Machine Number" value={machine.name} />
              <DataContainer label="Description" value={machine.description} />
              <DataContainer label="Status" value={machine.status} />
              <DataContainer label="Comments" value={machine.comments} />
              <DataContainer label="Date Created" value={new Date(machine.createdAt).toLocaleString() || 'N/A'} />
              <DataContainer label="Date Updated" value={new Date(machine.updatedAt).toLocaleString() || 'N/A'} />
            </Box>

            <Divider sx={{ margin: '24px 0 24px 0' }} />

            <Typography variant="h6" fontWeight="bold" gutterBottom>Operator Information</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }} >
              <DataContainer label="Operators" value={machine.operators.join(', ')} />
            </Box>

            <Divider sx={{ margin: '24px 0 24px 0' }} />

            <Typography variant="h6" fontWeight="bold" gutterBottom>Maintenance History</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }} >
              <DataContainer label="Last Maintenance" value={new Date(machine.lastMaintenanceDate).toLocaleString() || 'N/A'} />
              <DataContainer label="Next Maintenance" value={new Date(machine.nextMaintenanceDate).toLocaleString() || 'N/A'} />
              {machine.maintenanceHistory.length === 0 ? (
                <DataContainer label="Maintenance" value="No maintenance history" />
              ) : (
                <>
                  {machine.maintenanceHistory.map((maintenance, index) => (
                    <Box key={index}>
                      <DataContainer label="Date" value={new Date(maintenance.date).toLocaleString()} />
                      <DataContainer label="Notes" value={maintenance.notes} />
                      <DataContainer label="Performed By" value={maintenance.performedBy} />
                    </Box>
                  ))}
                </>
              )}

            </Box>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
              <DialogTitle>Delete Machine</DialogTitle>
              <DialogContent>
                <Typography variant="body1">Are you sure you want to delete this machine?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteCancel}>Cancel</Button>
                <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </>
      )}
    </Paper>
  )
};

export default MachineDetails;